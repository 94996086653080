<template>
  <div class="box">
    <div class="headerContentbox">
      <div class="iconBox" @click="handleSelect('1')"></div>
      <div class="menuBox">
        <el-menu
          class="el-menu-demo"
          :default-active="activeIndex2"
          text-color="#333333"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">首&nbsp;&nbsp;页</el-menu-item>
          <el-submenu index="2" @click.native="handleSelect('2')">
            <template slot="title">核心能力</template>
            <el-menu-item index="2-1">实时智慧监护技术</el-menu-item>
            <el-menu-item index="2-2">远程智慧监护中心</el-menu-item>
          </el-submenu>

          <el-submenu index="3" @click.native="handleSelect('3')">
            <template slot="title">服务能力</template>
            <el-menu-item index="3-1">老年健康监护</el-menu-item>
            <el-menu-item index="3-2">应急响应</el-menu-item>
            <el-menu-item index="3-3">智慧健康养老</el-menu-item>
          </el-submenu>
          <el-submenu index="4" @click.native="handleSelect('4')">
            <template slot="title">解决方案</template>
            <el-menu-item index="4-1">居家+健康监护</el-menu-item>
            <el-menu-item index="4-2">医养+健康监护</el-menu-item>
            <el-menu-item index="4-3">养老+健康监护</el-menu-item>
            <el-menu-item index="4-4">金融增值服务</el-menu-item>
            <el-menu-item index="4-5">企业员工福利</el-menu-item>
            <el-menu-item index="4-6">老干保健服务</el-menu-item>
            <!-- <el-menu-item index="4-7">智慧健康养老</el-menu-item>-->
            <el-menu-item index="4-12">适老环境改造</el-menu-item>
          </el-submenu>

          <el-menu-item index="5">关于我们</el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: ["hideTable", "clockButtonFlags"],
  data() {
    return {
      activeIndex2: "",
    };
  },
  mounted() {
    // this.initChart();
  },
  watch: {
    clockButtonFlags(newVal, oldVal) {
      this.activeIndex2 = newVal;
    },
  },
  methods: {
    handleSelect(v) {
      console.log(12122, v);
      if (document.getElementById(v)) {
        document.getElementById(v).scrollIntoView();
        console.log("结束");
        return;
      }
      this.$emit("hideTableFun", v);
    },
  },
};
</script>

<style scoped>
.box {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.headerContentbox {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
}
.iconBox {
  float: left;
  width: 163px;
  height: 50px;
  margin-top: 15px;
  background: url("../assets/logoIcon.png") no-repeat center center;
  background-size: 100% 100%;
  cursor: pointer;
}
.menuBox {
  float: right;
  /* height: 100%;
  width: 624px; */
  margin-top: 15px;
}
.el-menu.el-menu--horizontal {
  border-bottom: solid 1px #ffffff00;
}


.el-menu-item {
  /* margin: 0 20px !important; */
  font-size: 18px !important;
}
.el-submenu:nth-child(2),
.el-submenu:nth-child(3),
.el-submenu:nth-child(4) {
  width: 120px;
}
.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  width: auto;
}

.el-submenu ::v-deep.el-submenu__title {
  height: 60px;
  line-height: 60px;
  font-size: 18px !important;
}
.el-menu-demo {
  /* width: 110px;
  background: #000; */
}

.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title {
  height: 45px;
  line-height: 45px;
}

</style>