import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const routerMap = [
  {
    path: '',
    component: () => import('@/views/Index'),
    hidden: true,
  },
];

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: routerMap,
});
