<template>
  <!-- 首页 -->
  <div class="homeMainBox">
    <div class="bannerOneBox">
      <div class="bannerTextBox">
        <div class="textstyleO">银康数字化老年健康照护解决方案</div>
        <div class="textstyleT">
          YINKANG DIGITAL ELDERLY HEALTH CARE SOLUTIONS
        </div>
        <div class="textstyleTh">让长者远离健康风险</div>
      </div>
    </div>
    <!-- 老年健康自助终端APP -->
    <div
        class="SolutionTextItemBox"
        v-for="item in appTextItemData"
        :key="item.id"
    >
      <h3 class="SolutionTextItemBoxH3" :id="[1 + '-' + `${item.id}`]">
        {{ item.title }}
      </h3>
      <div class="SolutionTextItemContentBox">
        <div class="ItemContentleft" :style="{ background: item.backgroundL }">
          <img
              :src="require(`../../assets/${item.img}.png`)"
              alt=""
              width="120"
          />
          <div>{{ item.introduce }}</div>
        </div>
        <div class="ItemContentRight" :style="{ background: item.backgroundR }">
          <div
              class="rightData"
              :style="{ background: item.backgroundR }"
              v-for="one in item.rightData"
              :key="one.id"
          >
            <div class="h3styleOne" :id="[1 + '-' + `${one.id}`]" style="margin: 25px 0;">{{ one.title }}</div>
            <div
                class="contentItemThlistStyle listTwo"
                v-for="two in one.list"
                :key="two.id"
            >
              <div v-html="two.text"></div>
              <img v-if="two.img"
                   :src="require(`../../assets/${two.img}.png`)"
                   alt=""
                   width="120" style="margin-top: 5px;margin-left: 10px;"
              />
              <div style="width: 800px">{{ two.value }}</div>
            </div>
            <div class="contentItemThbox" style="margin-top: 30px;">
              <div
                  class="contentItemTh"
                  :style="{ background: item.backgroundR }"
                  v-for="th in one.contentItem"
                  :key="th.id"
              >
                <div class="h3style">{{ th.title }}</div>
                <div class="contentItemThlistBox">
                  <div
                      class="contentItemThlistStyle"
                      v-for="f in th.list"
                      :key="f.id"
                  >
                    <div>{{ f.text }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="font-size: 15px; color: transparent; background: #fff">
              {{ one.contentItemFlag }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频播放器 -->
    <div class="bannerVideoBox">
      <div class="healthGuardian">
        <div>健康守护<br/>爱在身边</div>
      </div>
      <div class="videoBox">
        <i class="start-play" @click="changeVideoStatus()"> </i>
      </div>
    </div>
    <!-- 视频弹框 -->
    <el-dialog :visible.sync="dialogTableVisible" show-close @close="closeVideoDialog">
      <video
          id="my-video"
          ref="videoRef"
          src="https://www.zhangzhegongshe.com/cdn/zzgs/videos/company_introduction_0505.mp4"
          controls=""
          poster="../../assets/videobg.png"
          preload="auto"
          webkit-playsinline="true"
          playsinline="true"
          x-webkit-airplay="allow"
          x5-playsinline
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-orientation="portraint"
          style="object-fit: fill; height: 60vh; border-radius: 5px"
          @play="videoIsPlay = true"
          @pause="videoIsPlay = false"
          @ended="videoIsPlay = false"
          loop="true"
          autoplay="true"
      >
      </video>
      <div slot="footer" class="dialog-footer">
        <el-button
            @click="closeVideoDialog()"
            style="margin: 10px 20px"
        >关 闭
        </el-button>
      </div>
    </el-dialog>
    <!-- 防范健康风险 -->
    <div class="healthRisksBox">
      <div class="healthRisksContent">
        <h1 class="healthRisksContenth1">防范健康风险</h1>
        <h3>银康数字化老年健康照护解决方案，为长者监测和防范以下风险</h3>
        <div class="riskbuttonBox">
          <div
              v-for="item in riskButondata"
              :key="item.name"
              class="riskbutton"
          >
            <img
                :src="require(`../../assets/${item.img}.png`)"
                alt=""
                width="90"
            />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 老年健康照护数字化核心能力 -->
    <div class="digitizationBox">
      <h1
          class="healthRisksContenth1"
          style="cursor: pointer"
          @click="handleSelect('2')"
      >
        老年健康照护数字化核心能力
      </h1>
      <div class="digitizationChart"></div>
      <div class="digitizationData">
        <div class="digitizationDataText">
          <div class="digitizationTextBox">
            <div class="digitizationstyle"></div>
            <div
                class="digitizationText"
                v-for="item in digitizationTextdata"
                :key="item.id"
            >
              <div v-if="item.id != 9" class="digitizationText_0"></div>
              {{ item.name }}
            </div>
          </div>
          <!-- <div class="digitizationTextBtbox">
            <el-button type="primary" style="background: '#0F6BB0'"
              >查看更多 ></el-button
            >
          </div> -->
        </div>
        <div class="digitizationDataBt">
          <div
              style="width: 50%; border-right: 10px #fff solid; cursor: pointer; "
              :class="[
              healthRisksContenth3flag == 1 ? 'digitizationDataBtL' : '',
            ]"
              @click="healthRisksContenth3(1)"
          >
            <h3
                :class="[
                healthRisksContenth3flag == 1
                  ? 'healthRisksContenth3L'
                  : 'healthRisksContenth3L',
              ]"
            >
              实时智慧监护技术
            </h3>
          </div>
          <div
              style="width: 50%; cursor: pointer;"
              :class="[
              healthRisksContenth3flag == 2 ? 'digitizationDataBtL' : '',
            ]"
              @click="healthRisksContenth3(2)"
          >
            <h3
                :class="[
                healthRisksContenth3flag == 2
                  ? 'healthRisksContenth3L'
                  : 'healthRisksContenth3L',
              ]"
            >
              远程智慧监护中心
            </h3>
          </div>
        </div>
      </div>
    </div>
    <!-- 数字化老年健康照护服务能力 -->
    <div class="healthCare">
      <h1
          class="healthRisksContenth1"
          style="color: #fff; cursor: pointer;"
          @click="handleSelect('3')"
      >
        数字化老年健康照护服务能力
      </h1>
      <div class="healthCareContentBox">
        <div
            class="healthCareContentItem"
            v-for="item in healthCareContentdata"
            :key="item.id"
        >
          <img
              :src="require(`../../assets/${item.img}.png`)"
              alt=""
              width="100%"
          />
          <div class="ContentBox">
            <div class="ContentTitle" @click="handleSelect(`3-${item.id}`)">
              {{ item.name }}
            </div>
            <div class="introducetext">{{ item.introduce }}</div>
            <div class="categoryBox">
              <div class="categoryText" v-for="i in item.category" :key="i.id">
                {{ i.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 老年健康监护的好处 -->
    <div class="benefitbox">
      <div class="healthRisksContenth1">老年健康监护的好处</div>
      <div class="benefitContentBox">
        <div class="benefitContentItem">
          <img
              class="benefitContentItemImgAnime"
              src="../../assets/benefitOneImg.png"
              width="380"
              alt=""
          />
          <div class="benefitContentList">
            <p>提供数据驱动的老年健康循证洞察力</p>
            <p>通过连续监测掌握长者健康实时动态</p>
            <p>帮助长者提高自我健康管理的依从性</p>
          </div>
        </div>
        <div class="benefitContentItem">
          <div class="benefitContentList">
            <div>提供对健康风险进行干预的能力</div>
            <div>降低医疗和护理费用</div>
            <div>对意外、伤害风险的实时应对</div>
          </div>
          <img
              class="benefitContentItemImgAnime"
              src="../../assets/benefitTwoImg.png"
              width="380"
              alt=""
          />
        </div>
        <div class="benefitContentItem">
          <img
              class="benefitContentItemImgAnime"
              src="../../assets/benefitThImg.png"
              width="380"
              alt=""
          />
          <div class="benefitContentList">
            <p>减少潜在、可避免的急诊和住院</p>
            <p>加强家人/照护者的连接和参与</p>
            <p>提高长者的体验和满意度</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 适用场景 -->
    <div class="applicableScenario">
      <h1 class="healthRisksContenth1">适用场景</h1>
      <div class="applicableScenarioContentBox">
        <div
            class="applicableScenarioItem"
            v-for="item in applicableScenarioData"
            :key="item.id"
            @click="handleSelect(`4-${item.id}`)"
        >
          <img
              :src="require(`../../assets/${item.img}.png`)"
              alt=""
              width="120"
          />
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeP",
  props: {},
  data() {
    return {
      dialogTableVisible: false,
      videoIsPlay: false,
      healthRisksContenth3flag: 1,
      applicableScenarioData: [
        {name: "居家健康监护", img: "homehealthmonitoringImg", id: 1},
        {name: "医养结合机构", img: "nursingInstitutionsImg", id: 2},
        {name: "养老机构", img: "PensionInstitutionsImg", id: 3},
        {name: "金融增值服务", img: "valueAddedImg", id: 4},
        {name: "企业员工福利", img: "employeeWelfareImg", id: 5},
        {name: "老干保健服务", img: "VeteranHealthImg", id: 6},
        {name: "智慧健康养老", img: "IntelligentPensionImg", id: 7},
        {name: "适老环境改造", img: "EnvironmentalTransformationImg", id: 8},
      ],
      riskButondata: [
        {name: "心血管疾病风险", img: "cardiovascularImg"},
        {name: "失能风险", img: "DisabilityImg"},
        {name: "衰弱风险", img: "weakImg"},
        {name: "营养不良风险", img: "innutritionImg"},
        {name: "跌倒风险", img: "fallImg"},
        {name: "安全风险", img: "securityImg"},
      ],
      digitizationTextdata: [
        {name: "智能传感设备集群", id: 1},
        {name: "聚焦健康老龄化关键因子", id: 2},

        {name: "健康顾问7×24小时监护服务", id: 7},
        {name: "远程数字监测技术", id: 6},

        {name: "监测和服务信息实时反馈", id: 3},
        {name: "生命体征数据持续监测", id: 4},

        {name: "异常情况紧急响应", id: 8},
        {name: "", id: 9},

        {name: "风险预警、报警和干预指导", id: 5},
        {name: "远程数字监测技术", id: 10},
      ],
      healthCareContentdata: [
        {
          name: "老年健康监护",
          img: "healthMonitoring",
          introduce:
              "早期发现窦性心动过缓、呼吸暂停、心律不齐、房颤、睡眠障碍、压疮、夜尿症、平衡障碍、营养不良、肥胖等健康风险",
          category: [
            {name: "7×24小时监护服务", id: 1},
            {name: "健康资讯分享", id: 3},
            {name: "监测人员专业监护", id: 4},
            {name: "异常情况实时通知及警报", id: 5},
            {name: "干预服务指导", id: 2},
          ],
          id: 1,
        },
        {
          name: "应急响应",
          img: "emergencyImg",
          introduce:
              "在紧急情况下按下设备的SOS键或通过行为识别设备的实时捕捉监测，将第一时间触发应急响应服务",
          category: [
            {name: "7×24小时监护服务", id: 1},
            {name: "监测人员专业监护", id: 2},
            {name: "紧急情况即时响应", id: 3},
            {name: "实时通知家人/照护者/保健小组", id: 4},
          ],
          id: 2,
        },
        {
          name: "适老环境改造",
          img: "environmentalTransformation",
          introduce:
              "为提升健康老龄化其中最重要的支撑因素——长者生活环境，提供适老化家具、适老生活辅具等改造和配置方案",
          category: [
            {name: "适老家具", id: 1},
            {name: "适老辅具", id: 2},
          ],
          id: 3,
        },
      ],
      appTextItemData: [{
        id: 1,
        title: "老年健康自助终端APP下载",
        backgroundL: "#987DE6",
        backgroundR: "#F2EFFC",
        colorR: "#6E648D",
        img: 'home/ykyh_logo',
        introduce:
            "老年健康自助终端APP可长期、持续采集和分析老年人健康数据，运用活力能力评估模型，评定活力能力状态，早期识别潜在的失能风险。",
        rightData: [
          {
            id: 11,
            title: "应用介绍",
            list: [
              {
                id: 1,
                text: "老年健康自助终端系银康优护信息平台的APP应用，为专业服务延伸覆盖社区居家老年人群提供内在能力筛查与监测、老年综合评估、老年健康风险报告、干预措施、服务推荐、服务预约、服务购买等功能。",
              }
            ],
            contentItemFlag: "　",
            contentItem: [],
          },
          {
            id: 12,
            title: "应用下载",
            list: [
              {
                id: 1,
                text: "发布日期：2023-06-28",
              },
              { id: 2, text: `发布版本：老年健康自助终端 V1.6.6 for Android  <a href="https://mp.zhangzhegongshe.com/cdn/zzgs/apk/lnjkzzzd.apk"
                    style="color: #0f6bb0; text-decoration:none;font-weight: bold;margin-left: 5px;font-family: PingFangSC-Regular, PingFang SC;">下载最新版本</a>` },
              { id: 3, text: "主要更新：功能新增与优化；修复了一些已知问题。" },
              { id: 4, value: "扫描二维码下载", img:"home/downloadApk"},
              { id: 5, text: `配套初始化应用：<a href="https://mp.zhangzhegongshe.com/cdn/zzgs/apk/bdsrf.apk" style="color: #0f6bb0; text-decoration:none;font-weight: bold;margin-left: 5px;font-family: PingFangSC-Regular, PingFang SC;">百度输入法（Android）</a>、<a href="https://mp.zhangzhegongshe.com/cdn/zzgs/apk/kdxfznyyyq.apk" style="color: #0f6bb0; text-decoration:none;font-weight: bold;margin-left: 5px;font-family: PingFangSC-Regular, PingFang SC;">科大讯飞智能语音引擎（Android）</a>`},
            ],
            contentItemFlag: "",
            contentItem: [],
          },
        ],
      }],
      appVersion: {}
    };
  },
  mounted() {
    this.getAppJson();
    document.querySelector(".bannerOneBox").style.height =
        (document.body.clientWidth / 19.2) * 6 + "px";
    window.addEventListener("resize", () => {
      document.querySelector(".bannerOneBox").style.height =
          (document.body.clientWidth / 19.2) * 6 + "px";
    });
  },
  methods: {
    handleSelect(v) {
      console.log("首页", v, "gg", v.charAt(2));
      if (document.getElementById(v)) {
        document.getElementById(v).scrollIntoView();
        console.log("结束");
        return;
      }

      if (v.charAt(2) < 7) {
        this.$emit("hideTableFunP", v);
      }
      if (v.charAt(2) == 7) {
        this.$emit("hideTableFunP", "3-3");
      }
      if (v.charAt(2) == 8 || v == "3-3") {
        this.$emit("hideTableFunP", "4-12");
      }
    },
    changeVideoStatus() {
      this.dialogTableVisible = true;
      try {
        const video = this.$refs.videoRef;
        video.play();
      } catch (e) {
        console.log('首次触发=' + e);
        setTimeout(function () {
          try {
            const video = this.$refs.videoRef;
            video.play();
          } catch (e) {
            console.log('再次触发=' + e);
          }
        }, 1000);
      }
    },
    closeVideoDialog() {
      this.dialogTableVisible = false;
      try {
        const video = this.$refs.videoRef;
        video.pause();
      } catch (e) {
        console.log(e);
      }
    },
    healthRisksContenth3(q) {
      this.healthRisksContenth3flag = q;
      if (q == 1) {
        if (document.getElementById("2-1")) {
          document.getElementById("2-1").scrollIntoView();
          return;
        }
        this.$emit("hideTableFunP", "2-1");
      }
      if (q == 2) {
        if (document.getElementById("2-2")) {
          document.getElementById("2-2").scrollIntoView();
          return;
        }
        this.$emit("hideTableFunP", "2-2");
      }
    },
    getAppJson() {
      this.$ajax({
        method: 'get',
        url: "/website/cdn/appVersion/appVersion.json?t="+new Date().getTime(),
      })
          .then(response => {
            var data = {};
            Object.assign(data, JSON.parse(JSON.stringify(response.data)));
            let lst = this.appTextItemData[0].rightData[1].list || [];
            lst[0].text = "发布日期：" + data.updateTime;
            lst[1].text = `发布版本：老年健康自助终端 ` + data.version + ` for Android  <a href="` + data.appLatest.url + `" style="color: #0f6bb0; text-decoration:none;font-weight: bold;margin-left: 5px;font-family: PingFangSC-Regular, PingFang SC;">` + data.appLatest.name + `</a>`;
            lst[2].text = data.updateText;
            let lstPlugin = data.initPlugin || [];
            let plugin = ``;
            for (let i = 0; i < lstPlugin.length; i++) {
              let tmp = lstPlugin[i] || {};
              plugin += `<a href="` + tmp.url + `" style="color: #0f6bb0; text-decoration:none;font-weight: bold;margin-left: 5px;font-family: PingFangSC-Regular, PingFang SC;">` + tmp.name + `</a>`;
              if (i < lstPlugin.length - 1) {
                plugin += `、`;
              }
            }
            lst[4].text = `配套初始化应用：` + plugin;
          })
    }
  },
};
</script>

<style scoped>
.homeMainBox {
  overflow: hidden;
  width: 100%;
}

.bannerOneBox {
  width: 100%;
  position: relative;
  min-width: 1200px;
  background: url(../../assets/homebannerOne_3.png) no-repeat center center;
  background-size: 100% 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bannerTextBox {
  width: 1200px;
  color: #fff;
  text-align: left;
}

.textstyleO {
  font-size: calc(60 / 19.2 * 1vw);
  height: 1.05rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 1.05rem;
}

.textstyleT {
  height: 0.4125rem;
  font-size: calc(24 / 19.2 * 1vw);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.4125rem;
  /* letter-spacing: 10px; */
  margin-bottom: 0.5rem;
}

.textstyleTh {
  font-size: calc(34 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.6rem;
}

.bannerVideoBox {
  width: 1200px;
  height: 500px;
  margin: 0.75rem auto;
  /* background-color: rgb(231, 34, 34); */
  display: flex;
}

.healthGuardian {
  height: 100%;
  width: 420px;
  background: url(../../assets/HealthGuardianBg.png) no-repeat center center;
  background-size: 100% 100%;
}

.healthGuardian div {
  font-size: calc(40 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 56px;
  margin-top: 140px;
  position: relative;
}

.healthGuardian div::after {
  content: "";
  position: absolute;
  top: 130px;
  left: 173px;
  width: 73px;
  height: 6px;
  background: #ffffff;
}

.videoBox {
  height: 100%;
  width: 780px;
  background: url(../../assets/videobg.png) no-repeat center center;
  background-size: 100% 100%;
  position: relative;
}

#my-video,
#my-video1 {
  height: 100%;
  width: 100%;
}

::v-deep .el-dialog__header {
  display: none;
  padding: 0px !important;
}

::v-deep .el-dialog__body {
  box-sizing: border-box;
  padding: 20px 20px 0px;
}

::v-deep .el-dialog__footer {
  /* display: none; */
  font-size: 0px;
  padding: 0px !important;
}

::v-deep .el-dialog {
  border-radius: 5px;
}

.healthRisksBox {
  width: 100%;
  height: 536px;
  background: url(../../assets/healthRisksbg.png) no-repeat center center;
  background-size: 100% 100%;
}

.healthRisksContent {
  width: 1200px;
  height: 100%;
  margin: 0px auto;
}

.healthRisksContenth1 {
  display: inline-block;
  margin: 0.75rem auto 0px;
  position: relative;
  font-size: calc(50 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 70px;
}

.healthRisksContenth1::after {
  content: "";
  position: absolute;
  top: 92px;
  left: 50%;
  transform: translate(-50%);
  width: 120px;
  height: 8px;
  background: #0f6bb0;
}

.healthRisksContent h3 {
  width: 800px;
  height: 48px;

  background: linear-gradient(
      270deg,
      rgba(188, 224, 120, 0) 0%,
      rgba(151, 199, 79, 0.96) 24%,
      #91c445 73%,
      #91c444 75%,
      rgba(135, 189, 67, 0) 100%
  );
  font-size: calc(20 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 48px;
  margin: 56px auto 0px;
}

.riskbuttonBox {
  width: 100%;
  margin: 30px auto;
  display: flex;
}

.riskbutton {
  width: 206px;
  height: 214px;
  background: url(../../assets/riskButtonbg.png) no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.riskbutton:hover {
  /* box-shadow: -2px 2px 10px #e5dede inset; */
}

.riskbutton span {
  font-size: calc(24 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0f6bb0;
  line-height: 33px;
  margin-top: 15px;
}

/* 数字化 */
.digitizationBox {
  width: 1200px;
  margin: 0 auto;
  /* background-color: rgb(43, 59, 166); */
}

.digitizationChart {
  width: calc(100% + 0px);
  height: 580px;
  margin-top: 46px;
  background: url(../../assets/digitizationChart.png) no-repeat center center;
  background-size: 100% 100%;
}

.digitizationData {
  width: 100%;
  /* height: 316px; */
  position: relative;
  top: -10px;
  /* padding: 10px; */
  box-sizing: border-box;
}

.digitizationData::before {
  content: "";
  position: absolute;
  /* height: 154px; */
  height: 100%;
  width: 10px;
  top: 50%;
  left: 50%;
  background: rgb(255, 255, 255);
  transform: translate(-50%, -50%);
}

.digitizationDataText {
  width: calc(100% - 20px);
  /* height: 206px; */
  background: #f3faff;
  margin: 0 auto;
  overflow: hidden;
}

.digitizationTextBox {
  margin-top: 20px;
  width: 100%;
  height: 154px;
  display: flex;
  flex-wrap: wrap;
}

.digitizationText {
  width: calc(100% / 4);
  height: 30px;
  text-align: left;
  font-size: .25rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0f6bb0;
  line-height: 30px;
  position: relative;
  padding-left: .55rem;
  box-sizing: border-box;
}

.digitizationText::before {
  content: "";
  width: 6px;
  height: 6px;
  background: #b00f0f00;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translate(0, -50%);
}

.digitizationText_0 {
  width: 6px;
  height: 6px;
  background: #0f6ab0;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: .35rem;
  transform: translate(0, -50%);
}

.digitizationTextBtbox {
  margin-top: 20px;
  width: 100%;
  /* height: 102px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.digitizationDataBt {
  width: calc(100% - 20px);
  height: 110px;
  background: #f7f7f7;
  margin: 0 auto;
  display: flex;
}

.digitizationDataBtL {
  /* background: #ffffff; */
}

.healthRisksContenth3 {
  display: inline-block;
  position: relative;
  font-size: calc(28 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #999999;
  line-height: 40px;
}

.healthRisksContenth3::after {
  content: "";
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%);
  width: 50px;
  height: 4px;
  background: #999999;
}

.healthRisksContenth3L {
  display: inline-block;
  position: relative;
  font-size: calc(28 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0f6bb0;
  line-height: 40px;
}

.healthRisksContenth3L::after {
  content: "";
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%);
  width: 50px;
  height: 4px;
  background: #0f6bb0;
}

/* 数字化老年健康照护服务能力 */
.healthCare {
  width: 100%;
  height: 920px;
  background: url(../../assets/healthCareBg.png) no-repeat center center;
  background-size: 100% 100%;
  margin-top: 0.875rem;
}

.healthCare h1::after {
  background-color: #fff;
}

.healthCareContentBox {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 56px;
  position: relative;
}

.healthCareContentItem {
  width: 390px;
  height: 652px;
  background: #fff;
  border-radius: 15px;
}

.ContentBox {
  padding: 30px 20px 0px;
}

.ContentTitle {
  text-align: left;
  height: 56px;
  font-size: calc(40 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 56px;
  position: relative;
  cursor: pointer;
}

.ContentTitle::after {
  content: "";
  position: absolute;
  top: 66px;
  left: 0px;
  width: 40px;
  height: 4px;
  background: #0f6bb0;
}

.introducetext {
  margin-top: 35px;
  font-size: calc(20 / 19.2 * 1vw);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
  text-align: justify;
  word-break: normal;
}

.categoryBox {
  position: absolute;
  bottom: 40px;
  height: 170px;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.categoryBox div:nth-child(5) {
  position: relative;
  left: -50px;
}

.categoryText {
  height: 42px;
  word-wrap: normal;
  font-size: calc(22 / 19.2 * 1vw);
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0f6bb0;
  line-height: 42px;
  position: relative;
  padding-left: 10px;
}

.categoryText::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #0f6bb0;
  position: absolute;
  top: 50%;
  left: -5px;
  transform: translate(0, -50%);
}

/* 老年健康监护的好处 */

.benefitbox {
  width: 960px;
  min-height: 1110px;
  margin: 0 auto;
}

.benefitContentBox {
  margin-top: 56px;
  margin-bottom: 80px;
}

.benefitContentItem {
  width: 100%;
  height: 380px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.benefitContentList {
  height: 100%;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
}

.benefitContentList p {
  width: 464px;
  height: 60px;
  background: rgb(15, 107, 176, 0.1);
  border-radius: 4px 100px 100px 100px;
  font-size: calc(24 / 19.2 * 1vw);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  text-align: left;
  color: #0f6bb0;
  line-height: 60px;
  position: relative;
  padding-left: 40px;
}

.benefitContentList p:hover {
  box-shadow: -2px 2px 10px #888888;
}

.benefitContentList div:hover {
  box-shadow: -2px 2px 10px #888888;
}

.benefitContentList p:nth-child(2) {
  position: relative;
  left: 40px;
}

.benefitContentList p:nth-child(3) {
  position: relative;
  left: 80px;
}

.benefitContentList p::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 20px;
  border-radius: 50%;
  transform: translate(0, -50%);
  width: 10px;
  height: 10px;
  background: #0f6bb0;
}

.benefitContentList div {
  width: 416px;
  height: 60px;
  background: rgba(15, 106, 176, 0.13);
  /* border-radius: 4px 100px 100px 25px; */
  border-radius: 100px 4px 100px 100px;
  font-size: calc(24 / 19.2 * 1vw);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0f6bb0;
  text-align: right;
  line-height: 60px;
  position: relative;
  padding-right: 50px;
  box-sizing: border-box;
}

.benefitContentList div:nth-child(2) {
  position: relative;
  left: -40px;
}

.benefitContentList div:nth-child(3) {
  position: relative;
  left: -80px;
}

.benefitContentList div::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 30px;
  border-radius: 50%;
  transform: translate(0, -50%);
  width: 10px;
  height: 10px;
  background: #0f6bb0;
}

/*  适用场景*/
.applicableScenario {
  width: 100%;
  height: 808px;
  background: url(../../assets/applicableScenarioBg.png) no-repeat center center;
  background-size: 100% 100%;
}

.applicableScenarioContentBox {
  width: 1200px;
  height: 538px;
  margin: 56px auto 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.applicableScenarioItem {
  width: 290px;
  height: 264px;
  /* background: rgb(255, 255, 255); */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(../../assets/applicableScenarioItebg.png) no-repeat center center;
  background-size: 100% 100%;
  cursor: pointer;
}

.applicableScenarioItem span {
  height: 40px;
  font-size: calc(28 / 19.2 * 1vw);
  margin-top: 10px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3a70e6;
  line-height: 40px;
}

/* 视频播放器 */
.start-play {
  cursor: pointer;
  width: 63px;
  height: 63px;
  background: linear-gradient(135deg, #eaebeb 0%, #feffff 100%);
  /* opacity: 0.7; */
  border-radius: 50%;
  display: block;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.start-play::after {
  content: "";
  width: 0;
  height: 0;
  border: 13px solid transparent;
  border-left: 19px solid rgb(88, 87, 87);
  position: absolute;
  top: 32%;
  left: 40%;
}

/* 老年自助终端app */
/* 556 */
.SolutionTextItemBox {
  width: 1200px;
  margin: 0 auto;
}

.SolutionTextItemBoxH3 {
  text-align: left;
  font-size: calc(40 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: .875rem;
  margin: .375rem 0px .375rem;
}

.SolutionTextItemContentBox {
  width: 100%;
  display: flex;
}

.ItemContentleft {
  /* width: 300px; */
  /* height: 744px; */
  padding: 40px 30px;
  box-sizing: border-box;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 400;
  color: #eee;
  line-height: 40px;
  text-align: justify;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ItemContentleft img {
  margin-bottom: 30px;
}

.ItemContentleft div:first-letter {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
}

.rightDatalist {
  text-align: left;
}

.contentItemThbox {
  display: flex;
  justify-content: space-between;
  width: 880px;
  background: #fff;
}

.h3style {
  /* height: 33px; */
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0a2336;
  line-height: 33px;
  text-align: left;
}

.h3styleOne {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0a2336;
  line-height: 33px;
  text-align: left;
  /* padding-top: 30px; */
  padding-left: 30px;
  box-sizing: border-box;
}

.contentItemTh {
  width: 430px;
  padding: 30px;
  box-sizing: border-box;

  /* display: flex; */
}

.contentItemThlistBox {
  margin-top: 20px;
  /* background: #000; */
}

.contentItemThlistStyle {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #7891a4;
  line-height: 32px;
  text-align: justify;
  padding-left: 22px;
  padding-right: 22px;
  box-sizing: border-box;
  position: relative;
  margin: 5px 0px;
}

.contentItemThlistStyle::before {
  content: "";
  width: 16px;
  height: 16px;
  background: url("../../assets/Solution/flagIcon.png");
  background-size: 100%;
  position: absolute;
  top: 10px;
  left: 0px;
}

.listTwo {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #7891a4;
  line-height: 32px;
  text-align: left;
  padding-left: 52px;
  box-sizing: border-box;
  position: relative;
  margin: 5px 0px;
}

.listTwo::before {
  content: "";
  width: 16px;
  height: 16px;
  background: url("../../assets/Solution/flagIcon.png");
  background-size: 100%;
  position: absolute;
  top: 10px;
  left: 30px;
}


/* 隐藏进度条 */
/* video::-webkit-media-controls-timeline {
  display: none;
} */
/* //隐藏全屏按钮 */
/* video::-webkit-media-controls-fullscreen-button {
  display: none;
} */
/* //隐藏播放按钮 */
/* video::-webkit-media-controls-play-button {
  display: none;
} */

/* //观看的当前时间 */
/* video::-webkit-media-controls-current-time-display {
  display: none;
} */
/* //剩余时间 */
/* video::-webkit-media-controls-time-remaining-display {
  display: none;
} */
/* //音量按钮 */
/* video::-webkit-media-controls-mute-button {
  display: none;
} */
/* video::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
} */

/* ///// */

/* *::-webkit-media-controls-enclosure {
  display: none !important;
  -webkit-appearance: none;
}
*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}
*::-webkit-media-controls-panel-container {
  display: none !important;
  -webkit-appearance: none;
}
*::--webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}
*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}
*::-webkit-media-controls {
  display: none !important;
  -webkit-appearance: none;
} */
</style>
