<template>
  <div class="footerBox">
    <div class="footerBoxContentBox">
      <div class="footerIconMenuBox">
        <div class="footerIconBox" @click="handleSelect('1')"></div>
        <div class="footerMenuBox">
          <el-menu
            class="el-menu-demo"
            :default-active="activeIndex2"
            text-color="#fff"
            active-text-color="#fff"
            background-color="transparent"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1">首&nbsp;&nbsp;页</el-menu-item>
            <el-menu-item index="2">核心能力</el-menu-item>
            <el-menu-item index="3">服务能力 </el-menu-item>
            <el-menu-item index="4">解决方案</el-menu-item>
            <el-menu-item index="5">关于我们</el-menu-item>
          </el-menu>
        </div>
      </div>
      <div class="agreementTelephoneBox">
        <div>
          <a href="https://www.zhangzhegongshe.com/cdn/explan/service_explan.html" target="_blank" style="margin-right: 20px;">用户服务协议</a>
          <a href="https://www.zhangzhegongshe.com/cdn/explan/privacy_policy.html" target="_blank">个人信息保护政策</a>
        </div>
        <div class="telephoneBox">
          <img src="../assets/Telephone.png" width="24" alt="" />&nbsp;
          {{ informationBasicBox.telephoneCompany }}
        </div>
      </div>
      <div class="informationBasicBox">
        <span>{{ informationBasicBox.nameCompany }}</span>
        <a href="https://beian.miit.gov.cn/" target="_blank">{{ informationBasicBox.numberCompany }}</a>
        <a href="https://www.zhangzhegongshe.com/cdn/explan/certificate_materials.html" target="_blank">{{ informationBasicBox.edi }}</a>
      </div>
      <div class="informationBasicBox">
        <a href="https://www.zhangzhegongshe.com/cdn/explan/certificate_materials.html" target="_blank">{{ informationBasicBox.drug }}</a>
        <a href="https://www.zhangzhegongshe.com/cdn/explan/certificate_materials.html" target="_blank">{{ informationBasicBox.class2 }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: ["clockButtonFlags"],
  data() {
    return {
      activeIndex2: "",
      informationBasicBox: {
        telephoneCompany: "400-6181-321",
        nameCompany: "© 2022 广州银康数字科技有限公司",
        numberCompany: "粤ICP备2021011555号",
        drug: "互联网药品信息服务资格证书（粤）-经营性-2021-0328",
        edi: "增值电信业务经营许可证 粤B2-20210715",
        class2: "第二类医疗器械经营备案凭证",
      },
    };
  },
  mounted() {
    // this.initChart();
  },
  watch: {
    clockButtonFlags(newVal, oldVal) {
      this.activeIndex2 = newVal;
    },
  },
  methods: {
    handleSelect(v) {
      this.$emit("hideTableFun", v);
      this.activeIndex2 = v;
    },
  },
};
</script>

<style scoped>
.footerBox {
  height: 100%;
  width: 100%;
  background-color: #002d4f;
  overflow: hidden;
}
.footerBoxContentBox {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
}
.footerIconMenuBox {
  margin-top: 0.4375rem;
  width: 100%;
  height: 60px;
}
.footerIconBox {
  float: left;
  height: 50px;
  width: 163px;
  background: url("../assets/logotransparent.png") no-repeat center center;
  background-size: 100% 100%;
  cursor: pointer;
}
.footerMenuBox {
  float: right;
}

.el-menu.el-menu--horizontal {
  border-bottom: solid 1px #ffffff00;
}
.el-menu-item {
  margin: 0 0.25rem !important;
}
/* .el-menu-item .is-active {
  background-color: transparent !important;
} */
.el-menu-item:hover {
  background-color: transparent !important;
}
.el-menu-demo .el-menu--horizontal .el-menu {
  background-color: transparent !important;
}

.agreementTelephoneBox {
  width: 100%;
  height: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.35rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.5rem;
  margin-top: 0.2rem;
  opacity: 0.4;
  border-bottom: 1px solid #D8D8D8;
}
.telephoneBox {
  display: flex;
  align-items: center;
}

.informationBasicBox {
  width: 100%;
  height: 0.45rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0.4;
}
.informationBasicBox > span, .informationBasicBox > a, .agreementTelephoneBox > div > a {
  height: 0.25rem;
  font-size: 0.175rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  /* color: rgb(80, 111, 134); */
  color: #fff;
  line-height: 0.25rem;
  text-underline: none;
  text-decoration: none;
}
</style>