F<template>
  <!-- 隐私条款 -->
  <div class="agreementUserBox">
    <h1>隐私条款</h1>
    <div
      class="agreementUserContentBox"
      v-for="item in agreementUserdata"
      :key="item.id"
    >
      <h3 class="agreementUserBoxH3">
        {{ item.title }}
      </h3>
      <p class="agreementUserBoxP ">
        {{ item.text }}
      </p>
      <div v-for="list in item.textList" :key="list.id">
        <span class="" style="display: block">{{ list.title }}</span>
        <span class="" style="display: block" v-for="i in list.list" :key="i.id">{{
          i.text
        }}</span>
      </div>
    </div>
  </div>
  <!-- 用户协议 -->
</template>

<script>
export default {
  name: "PrivacyClause",
  props: {},
  data() {
    return {
      agreementUserdata: [
        {
          id: 1,
          title: "一、《用户协议》条款的接受 请您认真阅读",
          text: "请您认真阅读本协议，尤其是免除或者限制本平台责任的条款及其它限制您权利的条款，一旦您注册即表示您已经阅读并且同意与本平台达成协议，完全理解并接受所有的《用户协议》条款。阅读本协议过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。",
          textList: [
            {
              id: 1,
              title: "",
              text: "",
            },
          ],
        },
        {
          id: 2,
          title: "二、用户注册条件",
          text: "",
          textList: [
            {
              id: 1,
              title: "1、用户申请注册成为本平台的用户应同时满足下列全部条件：",
              text: "",
              list: [
                {
                  text: "在注册之日以及此后使用本平台服务期间必须以恋爱或者婚姻为目的；",
                  id: 1,
                },
                {
                  text: "在注册之日以及此后使用本平台服务期间必须是单身状态，包括未婚、离异或是丧偶；",
                  id: 2,
                },
                {
                  text: "在注册之日必须年满18周岁以上。",
                  id: 3,
                },
              ],
            },
            {
              id: 2,
              title: "2、为更好的享有本平台提供的服务，用户应： ",
              text: "",
              list: [
                {
                  text: "向本平台提供本人真实、正确、最新及完整的资料；随时更新登记资料，保持其真实性及有效性；提供真实有效的联系人手机号码；征友过程中，务必保持征友账号的唯一性。",
                  id: 1,
                },
              ],
            },
            {
              id: 3,
              title:
                "3、若用户提供任何错误、不实或不完整的资料，或本平台有理由怀疑资料为错误、不实或不完整及违反本协议条款的，或本平台有理由怀疑用户的用户资料、言行等有悖于“严肃纯净的婚恋交友”主题的，本平台有权修改该用户的注册昵称、独白等，或暂停或终止该用户的账号，或暂停或终止提供本平台的全部或部分服务。  ",
              text: "",
              list: [
                {
                  text: "",
                  id: 1,
                },
              ],
            },
            {
              id: 4,
              title:
                "4、为保证用户信息的真实性，未经过会员认证的用户只能以游客身份进行浏览或浅层试用本产品功能（如打招呼等）。",
              text: "",
              list: [
                {
                  text: "",
                  id: 1,
                },
              ],
            },
          ],
        },

        {
          id: 3,
          title: "三、用户账号名称安全",
          text: "",
          textList: [
            {
              id: 1,
              title: "",
              text: "",
              list: [
                {
                  text: "任何注册和使用的本平台账号名称，不得有下列情形：",
                  id: 1,
                },
                {
                  text: "（一）违反宪法或法律法规规定的；",
                  id: 2,
                },
                {
                  text: "（二）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；",
                  id: 3,
                },

                {
                  text: "（三）损害国家荣誉和利益的，损害公共利益的；",
                  id: 4,
                },
                {
                  text: "（四）煽动民族仇恨、民族歧视，破坏民族团结的； ",
                  id: 5,
                },
                {
                  text: "（五）破坏国家宗教政策，宣扬邪教和封建迷信的；  ",
                  id: 6,
                },

                {
                  text: "（六）散布谣言，扰乱社会秩序，破坏社会稳定的；",
                  id: 7,
                },
                {
                  text: "（七）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；",
                  id: 8,
                },

                {
                  text: "（八）侮辱或者诽谤他人，侵害他人合法权益的； ",
                  id: 9,
                },
                {
                  text: "（九）含有法律、行政法规禁止的其他内容的。   ",
                  id: 10,
                },

                {
                  text: "用户以虚假信息骗取账号名称注册，或其账号头像、简介等注册信息存在违法和不良信息的，本平台有权采取通知限期改正、暂停使用、注销账号等措施。  ",
                  id: 11,
                },
                {
                  text: "对冒用关联机构或社会名人注册账号名称的用户，本平台有权注销其账号。用户应采取适当措施保障注册用户名和密码的机密性并对以该用户名和密码进行的所有活动承担全部责任。用户应确保于每次会话结束后退出账户，若发现未经授权使用该用户名或密码或其他侵犯其账户安全的任何行为立即通知本平台；本平台对未遵守上述规定而给用户或他人带来的任何损失不承担责任。",
                  id: 12,
                },
              ],
            },
          ],
        },
        {
          id: 4,
          title: "四、服务说明 ",
          text: "",
          textList: [
            {
              id: 1,
              title: "",
              text: "",
              list: [
                {
                  text: "1、本平台在提供网络服务时，可能会对部分网络服务收取一定的费用，在此情况下，会在相关页面上做明确的提示。如您拒绝支付该等费用，则不能使用相关的网络服务。您在本平台购买相关服务可选择使用您的第三方电子账户（包括但不限于微信支付账户或支付宝支付账户）进行支付，在处理您使用服务相关的付款时，除了受本协议及相关协议的约束，还要受电子支付服务商及信用卡/借记卡发卡行的条款和政策的约束，我们对于电子支付服务商或银行发生的错误不承担责任。付费业务将在本协议条款的基础上另行规定服务条款，以规范付费业务的内容和双方的权利义务，用户应认真阅读，如您购买付费业务，则视为接受付费业务的服务条款。  ",
                  id: 1,
                },
                {
                  text: "2、无论是付费业务还是本平台免费提供服务，上述服务均有有效期，有效期结束后服务将自动终止，且有效期不可中断或延期。除非本协议条款另有规定，否则所有付费业务均不退费。  ",
                  id: 2,
                },
                {
                  text: "3、对于利用本平台服务进行非法活动，或其言行（无论线上或者线下的）背离本平台严肃交友目的的，本平台将严肃处理，包括将其列入平台黑名单、删除用户账号等处罚措施，涉嫌刑事犯罪的，本平台有权向司法机关进行投诉、举报。  ",
                  id: 3,
                },

                {
                  text: " 4、本平台有权向您发送广告信或代其他用户向您发送消息，或为组织线下活动等目的，向您发送电子邮件、短信或电话通知。由于手机网络的特殊性，本平台有权获取您的手机信息，如手机号码或用户的基站位置等。  ",
                  id: 4,
                },
                {
                  text: "5、为提高本平台用户的交友成功率和效率，本平台在获取用户的授权后有权将本平台用户的交友信息在本平台的合作网站上进行展示或其他类似行为。  ",
                  id: 5,
                },
                {
                  text: "6、为了保障本平台用户账户安全，如用户连续180日内未登录注册账户（用户购买有明确期限付费服务的有效期未满除外），则自第180天当天的24时起，本平台有权对该账户进行冻结。用户重新启用账户应致电公司客服（），并通过相关验证。  ",
                  id: 6,
                },

                {
                  text: "7、用户不得将本平台及在本平台上的行为用作商业用途。除非经本平台书面授权，不得与任何商业行为相关联。任何非法和/或未经授权而使用本平台的行为（包括但不限于通过电子或其他方法），本平台有权采取封号及其他措施保护自身权益。  ",
                  id: 7,
                },
                {
                  text: "8、用户可通过充值豆币在本平台内购买写信服务或购买动感礼物、获得更多展示资格等其他增值服务。豆币的使用范围仅限于兑换本平台提供的产品和服务，不得用于支付、购买实物或兑换其他单位的产品和服务。",
                  id: 8,
                },

                {
                  text: " 9、部分女性用户可通过参与本平台定期或不定期提供的相关活动或服务获得相应的积分，积分可按照一定比例折算为人民币进行相应提现，具体提现规则将由本平台在相关服务页面上显示，本平台有权根据运营情况随时对提现规则进行调整。 ",
                  id: 9,
                },
              ],
            },
          ],
        },

        {
          id: 5,
          title: "五、用户个人信息保护",
          text: "",
          textList: [
            {
              id: 1,
              title: "",
              text: "",
              list: [
                {
                  text: "1、本平台非常重视您的个人信息保护，保证在合法、正当与必要的原则下收集、使用或者公开您的个人信息且不会收集与提供和本产品服务无关的个人信息。",
                  id: 1,
                },
                {
                  text: "2、您在注册账号或使用本服务的过程中，为了更好地向您提供相关服务，本平台可能需要您填写或提交一些必要的基础个人信息，如法律法规、规章规范性文件（以下称“法律法规”）规定的需要填写的身份信息。如您提交的信息不完整或不符合法律法规的规定，则您可能无法使用本服务或在使用本服务的过程中受到限制。 ",
                  id: 2,
                },
                {
                  text: "3、本平台收集的用户个人信息包括：1）用户在注册时需自行提供的必要个人信息（如注册时填写的手机号码、性别、年龄等个人信息）；2）本平台在提供服务过程中合法收集的用户个人信息，如用户使用服务时的设备信息、账号信息、日志信息、个人基本资料、好友信息等。请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息；3）地理位置信息：因本平台提供的服务系基于地理位置提供的移动社交服务，用户地理位置信息将作为用户公开资料之一，由本平台向其他用户公开以便本平台向用户提供基于地理位置的移动社交服务。在收集用户的地理位置信息前，本平台将单独向您发出权限开放申请，如您不愿意提供地理位置信息，可选择拒绝。您在提供地理位置信息后，也可随时通过您的设备系统设置终止向其他用户公开您的地理位置信息。 ",
                  id: 3,
                },
              ],
            },
            {
              id: 2,
              title: "4、本平台承诺，本平台收集您的个人信息将仅用于以下用途： ",
              text: "",
              list: [
                {
                  text: "（1）创建本平台账号，帮助您成为本平台注册用户；",
                  id: 1,
                },
                {
                  text: "（2）为您提供和展示推送本平台的商品或服务；",
                  id: 2,
                },
                {
                  text: "（3）为您提供安全保障； ",
                  id: 3,
                },

                {
                  text: "（4）本平台向用户及时发送重要通知，如软件更新、本协议条款的变更； ",
                  id: 4,
                },
                {
                  text: "（5）本平台内部进行审计、数据分析和研究等，以改进本平台的产品、服务和与用户之间的沟通；",
                  id: 5,
                },
                {
                  text: "（6）依本协议约定，本平台提供相关服务、管理、审查用户信息及进行处理措施； ",
                  id: 6,
                },
                {
                  text: "（7）适用法律法规规定的其他事项。 ",
                  id: 7,
                },
                {
                  text: "除上述事项外，如未取得用户事先同意，本平台不会将用户个人信息使用于任何其他用途。如果本平台将您的个人信息用于任何未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。 ",
                  id: 8,
                },
              ],
            },
            {
              id: 3,
              title:
                "5、本平台未经用户同意不会向任何第三方共享、转让、公开披露用户个人隐私信息。但以下特定情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：  ",
              text: "",
              list: [
                {
                  text: "（1）与国家安全、国防安全有关的； ",
                  id: 1,
                },
                {
                  text: "（2）与公共安全、公共卫生、重大公共利益有关的；",
                  id: 2,
                },
                {
                  text: "（3）与犯罪侦查、起诉、审判和判决执行等有关的； ",
                  id: 3,
                },

                {
                  text: "（4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； ",
                  id: 4,
                },
                {
                  text: "（5）您自行向社会公众公开的个人信息；",
                  id: 5,
                },
                {
                  text: "（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。 ",
                  id: 6,
                },
              ],
            },
            {
              id: 4,
              title:
                "6、本平台向用户提供账户注销功能，具体可通过APP内“我→更多设置→账户注销”的路径完成注销，也可联系本平台客服电话进行人工注销。通过账户注销功能，用户可删除其在本平台的相关个人信息或使其匿名化。  ",
              text: "",
              list: [
                {
                  text: "",
                  id: 1,
                },
              ],
            },
            {
              id: 5,
              title:
                "7、尊重个人信息的私有性是本平台的一贯制度，本平台将采取技术措施和其他必要措施，确保用户个人信息安全，防止在本服务中收集的用户个人信息泄露、毁损或丢失。在发生前述情形或者本平台发现存在发生前述情形的可能时，本平台将及时采取补救措施并告知用户，用户如发现存在前述情形亦需立即与本平台联系。  ",
              text: "",
              list: [
                {
                  text: "",
                  id: 1,
                },
              ],
            },
            {
              id: 6,
              title:
                "8、本平台重视对未成年人的保护。本平台将依赖用户提供的个人信息判断用户是否为未成年人。任何18岁以下的未成年人均不得注册账号或使用本服务。",
              text: "",
              list: [
                {
                  text: "",
                  id: 1,
                },
              ],
            },
            {
              id: 7,
              title:
                "9、本平台十分注重保护用户的个人信息，并单独制定了《隐私政策》，用户可在注册账号时查看，亦可通过“我”—“设置”—“关于我们”页面里的“隐私政策”来进行查看，用户确认并同意使用本平台提供的服务将被视为接受《隐私政策》。",
              text: "",
              list: [
                {
                  text: "",
                  id: 1,
                },
              ],
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.agreementUserBox {
  width: 1200px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 28px;
}
.agreementUserBox h1 {
  font-size: 50px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.agreementUserContentBox {
  width: 100%;
  text-align: left;
  margin: 60px auto;
}
.agreementUserBoxH3 {
  text-align: left;
  font-size: 24px;
}
.agreementUserBoxP {
  text-align: left;
  font-size: 16px;
}
.textstyle {
  font-size: calc(24 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
}
</style>

