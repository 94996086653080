<template>
  <!-- 解决方案 -->
  <div class="SolutionBox">
    <div class="bannerOneBox">
      <div class="bannerTextBox">
        <div class="textstyleO">解决方案</div>
        <div class="textstyleT">SOLUTIONS</div>
        <div class="textstyleTh" style="font-weight: 400">
          为用户和机构提供智慧健康老龄化解决方案
        </div>
      </div>
    </div>

    <div
      class="SolutionTextItemBox"
      v-for="item in SolutionTextItemData"
      :key="item.id"
    >
      <h3 class="SolutionTextItemBoxH3" :id="[4 + '-' + `${item.id}`]">
        {{ item.title }}
      </h3>
      <div class="SolutionTextItemContentBox">
        <div class="ItemContentleft" :style="{ background: item.backgroundL }">
          {{ item.introduce }}
        </div>
        <div class="ItemContentRight" :style="{ background: item.backgroundR }">
          <div
            class="rightData"
            :style="{ background: item.backgroundR }"
            v-for="one in item.rightData"
            :key="one.id"
          >
            <div style="font-size: 23px; color: transparent" >
              {{ one.title }}
            </div>
            <div class="h3styleOne" :id="[4 + '-' + `${one.id}`]">{{ one.title }}</div>
            <div style="font-size: 15px; color: transparent">
              {{ one.title }}
            </div>
            <div
              class="contentItemThlistStyle listTwo"
              v-for="two in one.list"
              :key="two.id"
            >
              {{ two.text }}
              <div style="width: 800px">{{ two.value }}</div>
            </div>
            <div style="font-size: 23px; color: transparent">
              {{ one.title }}
            </div>
            <div class="contentItemThbox">
              <div
                class="contentItemTh"
                :style="{ background: item.backgroundR }"
                v-for="th in one.contentItem"
                :key="th.id"
              >
                <div class="h3style">{{ th.title }}</div>
                <div class="contentItemThlistBox">
                  <div
                    class="contentItemThlistStyle"
                    v-for="f in th.list"
                    :key="f.id"
                  >
                    <div>{{ f.text }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="font-size: 15px; color: transparent; background: #fff">
              {{ one.contentItemFlag }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Solution",
  props: {},
  data() {
    return {
      SolutionTextItemData: [
        {
          id: 1,
          title: "居家老年健康解决方案",
          backgroundL: "#5CC3D4",
          backgroundR: "#EEF9FA",
          colorR: "#7891A4",
          introduce:
            "银康数科利用多维度的健康照护手段为居家长者提供综合的老年健康解决方案。帮助长者和家人掌握个体老年健康动态变化，尤其是关键机能和环境因子的细微变化，以循证为依据，消除、减缓各种可能导致能力下降的疾病、衰弱等健康风险和意外风险。",
          rightData: [
            {
              id: 11,
              title: "",
              title: "",
              contentItemFlag: "　",
              contentItem: [
                {
                  id: 1,
                  title: "居家老年健康监护解决方案",
                  list: [
                    {
                      id: 1,
                      text: "实时监测窦性心动过缓、呼吸暂停、心律不齐、房颤、睡眠障碍、夜尿症、平衡障碍、营养不良、肥胖、压疮等健康风险数据，智能分析用户的健康趋势及异常变化",
                    },
                    {
                      id: 2,
                      text: "通过手机客户端每天实时同步长者健康数据，推送健康风险预警信息，反馈监测报告",
                    },
                    { id: 3, text: "由监护中心健康顾问提供7×24小时全天监护" },
                  ],
                },
                {
                  id: 2,
                  title: "居家老年安全监护解决方案",
                  list: [
                    { id: 1, text: "SOS一键报警" },
                    { id: 2, text: "4D生物雷达精准探和识别跌倒意外" },
                    {
                      id: 3,
                      text: "燃气、烟雾、门窗开关等安全监测，由监护中心健康顾问提供7×24小时全天监护",
                    },
                  ],
                },
              ],
              list: [],
            },

            {
              id: 12,
              title: "居家适老环境解决方案",
              contentItem: [],
              contentItemFlag: "",
              list: [
                {
                  id: 1,
                  text: "适老家具",
                  value:
                    "包括沙发、座椅、茶几、桌子、床具、柜子六大类，产品种类多，款式多样，从适老、环保等方面入手，全面满足老年人对于家具功能、舒适、美观、稳固、防磕碰、易清洗的需求",
                },
                {
                  id: 2,
                  text: "适老卫浴辅具",
                  value: "适老洗浴辅具、适老如厕辅具",
                },
              ],
              flagstyle: "true",
            },
          ],
        },

        {
          id: 2,
          title: "医养结合机构解决方案",
          backgroundL: "#90B75D",
          backgroundR: "#F1F6EB",
          colorR: "#7A8D60",
          introduce:
            "银康数科帮助医养结合机构构建老年健康监护系统，监测入住长者的健康和意外风险，以便及时提供有效措施消除风险。",
          rightData: [
            {
              id: 21,
              title: "",
              title: "",
              contentItemFlag: "　",
              contentItem: [
                {
                  id: 1,
                  title: "老年健康监护",
                  list: [
                    {
                      id: 1,
                      text: "实时监测长者窦性心动过缓、呼吸暂停、心律不齐、房颤、睡眠障碍、夜尿症、平衡障碍、营养不良、肥胖、压疮等健康风险",
                    },
                    { id: 2, text: "离床监测、跌倒监测" },
                    { id: 3, text: "由监护中心健康顾问提供7×24小时全天监护" },
                  ],
                },
                {
                  id: 2,
                  title: "软硬一体化监护系统",
                  list: [
                    { id: 1, text: "智慧健康监护系统：前台应用、业务中台和数" },
                    { id: 2, text: "智能设备：健康监测设备、安全监测设备" },
                    { id: 3, text: "灵活的部署方式：云部署、混合部署、本地部署" },
                  ],
                },
              ],
              list: [],
            },
            {
              id: 22,
              title: "二级医疗机构开展医养结合服务支撑方案",
              list: [
                { id: 1, text: "老年医学科、老年病专业建设" },
                { id: 2, text: "医养结合服务调度中心建设" },
                { id: 3, text: "老年医护人员培训" },
                { id: 4, text: "示范机构评选指导" },
                { id: 5, text: "智慧医养结合软硬件赋能" },
              ],
              contentItemFlag: "",
              contentItem: [],
            },
          ],
        },
        {
          id: 3,
          title: "养老机构解决方案",
          backgroundL: "#00C28D",
          backgroundR: "#E5F8F3",
          colorR: "#5B8D7F",
          introduce:
            "银康数科帮助养老机构构建智慧健康养老系统和老年健康监护系统，监测入住长者的健康和意外风险，以便及时提供有效措施消除风险。",
          rightData: [
            {
              id: 31,
              title: "",
              title: "",
              contentItemFlag: "　",
              contentItem: [
                {
                  id: 1,
                  title: "老年健康监护",
                  list: [
                    {
                      id: 1,
                      text: "实时监测长者窦性心动过缓、呼吸暂停、心律不齐、房颤、睡眠障碍、夜尿症、平衡障碍、营养不良、肥胖、压疮等健康风险",
                    },
                    { id: 2, text: "离床监测、跌倒监测" },
                    { id: 3, text: "由监护中心健康顾问提供7×24小时全天监护" },
                  ],
                },
                {
                  id: 2,
                  title: "智慧健康养老系统",
                  list: [
                    {
                      id: 1,
                      text: "智慧健康养老平台：涵盖机构养老、居家养老、社区养老和政府监管的信息化管理需求",
                    },
                    {
                      id: 2,
                      text: "智慧健康养老设备：健康监测设备、安全监测设备、照护管理设备、长者饭堂设备、机构出入管理设备",
                    },
                  ],
                },
              ],
              list: [],
            },
            {
              id:32,
              title: "软硬一体化监护系统",
              list: [
                {
                  id: 1,
                  text: "智慧健康监护系统：前台应用、业务中台和数据中台",
                },
                { id: 2, text: "智能设备：健康监测设备、安全监测设备" },
                { id: 3, text: "灵活的部署方式：云部署、混合部署、本地部署" },
              ],
              contentItemFlag: "",
              contentItem: [],
            },
          ],
        },
        {
          id: 4,
          title: "金融机构健康监护增值服务解决方案",
          backgroundL: "#987DE6",
          backgroundR: "#F2EFFC",
          colorR: "#6E648D",
          introduce:
            "基于银康数科数字化老年健康照护的科技和服务能力，满足金融机构高净值客户老年健康照护服务刚需，提供优质高端增值服务。",
          rightData: [
            {
              id: 41,
              title: "健康监护增值服务",
              list: [
                {
                  id: 1,
                  text: "实时监测长者窦性心动过缓、呼吸暂停、心律不齐、房颤、睡眠障碍、夜尿症、平衡障碍、营养不良、肥胖、压疮等健康风险",
                },
                { id: 2, text: "离床监测、跌倒监测" },
                { id: 3, text: "由监护中心健康顾问提供7×24小时全天监护" },
              ],
              contentItemFlag: "　",
              contentItem: [],
            },
            {
              id: 42,
              title: "体验中心",
              list: [
                {
                  id: 1,
                  text: "在金融机构客户会所、俱乐部设置数字化老年健康照护服务体验中心",
                },
                { id: 2, text: "智能设备：健康监测设备、安全监测设备" },
              ],
              contentItemFlag: "",
              contentItem: [],
            },
          ],
        },
        {
          id: 5,
          title: "企业员工健康监护解决方案",
          backgroundL: "#E56E4F",
          backgroundR: "#FCF0ED",
          colorR: "#9B6B5F",
          introduce:
            "银康数科帮助企业职工监测家中长者的健康和意外风险，让职工安心本职工作和家庭生活。",
          rightData: [
            // {
            //   id: 1,
            //   title: "健康监护增值服务",
            //   list: [
            //     {
            //       id: 1,
            //       text: "实时监测长者窦性心动过缓、呼吸暂停、心律不齐、房颤、睡眠障碍、夜尿症、平衡障碍、营养不良、肥胖、压疮等健康风险",
            //     },
            //     { id: 2, text: "离床监测、跌倒监测" },
            //     { id: 3, text: "由监护中心健康顾问提供7×24小时全天监护" },
            //   ],
            //   contentItemFlag: "　",
            //   contentItem: [],
            // },
            {
              id: 52,
              title: "健康监护增值服务",
              list: [
                {
                  id: 1,
                  text: "实时监测长者窦性心动过缓、呼吸暂停、心律不齐、房颤、睡眠障碍、夜尿症、平衡障碍、营养不良、肥胖、压疮等健康风险",
                },
                { id: 2, text: "离床监测、跌倒监测" },
                { id: 3, text: "由监护中心健康顾问提供7×24小时全天监护" },
              ],
              contentItemFlag: "",
              contentItem: [],
            },
          ],
        },
        {
          id: 6,
          title: "老干保健健康监护解决方案",
          backgroundL: "#E6A84B",
          backgroundR: "#FCF4E9",
          colorR: "#89714D",
          introduce:
            "银康数科利用数字化老年健康照护技术，帮助干部休养所构建老年健康监护系统，监测离退休老干部的健康和意外风险。",
          rightData: [
            {
              id: 61,
              title: "老年健康监护",
              list: [
                {
                  id: 1,
                  text: "实时监测长者窦性心动过缓、呼吸暂停、心律不齐、房颤、睡眠障碍、夜尿症、平衡障碍、营养不良、肥胖、压疮等健康风险",
                },
                { id: 2, text: "离床监测、跌倒监测" },
                { id: 3, text: "由监护中心健康顾问提供7×24小时全天监护" },
              ],
              contentItemFlag: "　",
              contentItem: [],
            },
            {
              id: 62,
              title: "软硬一体化监护系统",
              list: [
                {
                  id: 1,
                  text: "智慧健康监护系统：前台应用、业务中台和数据中台",
                },
                { id: 2, text: "智能设备：健康监测设备、安全监测设备" },
                { id: 3, text: "灵活的部署方式：云部署、混合部署、本地部署" },
              ],
              contentItemFlag: "",
              contentItem: [],
            },
          ],
        },
      ],
    };
  },
  mounted() {
    document.querySelector(".bannerOneBox").style.height =
      (document.body.clientWidth / 19.2) * 6 + "px";

    window.addEventListener("resize", () => {
      document.querySelector(".bannerOneBox").style.height =
        (document.body.clientWidth / 19.2) * 6 + "px";
    });
  },
};
</script>

<style scoped>
.SolutionBox {
  overflow: hidden;
  width: 100%;
  margin-bottom: .875rem;
}
.bannerOneBox {
  width: 100%;
  position: relative;
  min-width: 1200px;
  background: url(../../assets/Solution/bannerSolution_3.png) no-repeat center
    center;
  background-size: 100% 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bannerTextBox {
  width: 1200px;
  color: #fff;
  text-align: left;
}
.textstyleO {
  font-size: calc(60 / 19.2 * 1vw);
  height: 1.05rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 1.05rem;
}
.textstyleT {
  height: 0.4125rem;
  font-size: calc(24 / 19.2 * 1vw);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.4125rem;
  /* letter-spacing: 10px; */
  margin-bottom: 0.5rem;
}
.textstyleTh {
  font-size: calc(34 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.6rem;
}

.healthRisksContenth1 {
  display: inline-block;
  margin: 0.75rem auto 0px;
  position: relative;
  font-size: calc(50 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 70px;
}
.healthRisksContenth1::after {
  content: "";
  position: absolute;
  top: 92px;
  left: 50%;
  transform: translate(-50%);
  width: 120px;
  height: 8px;
  background: #0f6bb0;
}

/* 556 */
.SolutionTextItemBox {
  width: 1200px;
  margin: 0 auto;
}
.SolutionTextItemBoxH3 {
  text-align: left;
  font-size: calc(40 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: .875rem;
  margin: .875rem 0px .375rem;
}

.SolutionTextItemContentBox {
  width: 100%;
  display: flex;
}

.ItemContentleft {
  /* width: 300px; */
  /* height: 744px; */
  padding: 40px 30px;
  box-sizing: border-box;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 400;
  color: #eee;
  line-height: 40px;
  text-align: justify;
  margin-right: 20px;
}
.ItemContentleft:first-letter {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
}

.rightDatalist {
  text-align: left;
}
.contentItemThbox {
  display: flex;
  justify-content: space-between;
  width: 880px;
  background: #fff;
}
.h3style {
  /* height: 33px; */
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0a2336;
  line-height: 33px;
  text-align: left;
}
.h3styleOne {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0a2336;
  line-height: 33px;
  text-align: left;
  /* padding-top: 30px; */
  padding-left: 30px;
  box-sizing: border-box;
}
.contentItemTh {
  width: 430px;
  padding: 30px;
  box-sizing: border-box;

  /* display: flex; */
}
.contentItemThlistBox {
  margin-top: 20px;
  /* background: #000; */
}
.contentItemThlistStyle {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #7891a4;
  line-height: 32px;
  text-align: justify;
  padding-left: 22px;
  box-sizing: border-box;
  position: relative;
  margin: 5px 0px;
}
.contentItemThlistStyle::before {
  content: "";
  width: 16px;
  height: 16px;
  background: url("../../assets/Solution/flagIcon.png");
  background-size: 100%;
  position: absolute;
  top: 10px;
  left: 0px;
}
.listTwo {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #7891a4;
  line-height: 32px;
  text-align: left;
  padding-left: 52px;
  box-sizing: border-box;
  position: relative;
  margin: 5px 0px;
}
.listTwo::before {
  content: "";
  width: 16px;
  height: 16px;
  background: url("../../assets/Solution/flagIcon.png");
  background-size: 100%;
  position: absolute;
  top: 10px;
  left: 30px;
}
</style>

