<template>
  <div>
    <div class="header">
      <Header
        :hideTable="flagModal"
        @hideTableFun="hideTableFunc"
        :clockButtonFlags="flagModal"
      />
    </div>
    <div class="main">
      <Main :clockButtonFlags="flagModal" @hideTableFun="hideTableFunc" />
    </div>
    <div class="footer">
      <Footer
        :hideTable="flagModal"
        @hideTableFun="hideTableFunc"
        :clockButtonFlags="flagModal"
      />
    </div>
    <el-backtop :bottom="20" :right="2">
      <div
        style="
           {
            height: 100%;
            width: 100%;
            background-color: #f2f5f6;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
            text-align: center;
            line-height: 40px;
            color: #1989fa;
          }
        "
      >
        <i class="el-icon-arrow-up"></i>
      </div>
    </el-backtop>
    <div class="videoboxup"></div>
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import Main from "../components/Main.vue";
export default {
  name: "Index",
  components: {
    Header,
    Main,
    Footer,
  },
  props: {},
  data() {
    return {
      flagModal: "1",
    };
  },
  methods: {
    hideTableFunc(xx) {
      this.flagModal = xx;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  /* background-color: #b3c0d1; */
  color: #333;
  text-align: center;
  height: 80px;
}
.footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  /*height: 3.125rem;*/
}

.main {
  /* background-color: #e9eef3; */
  color: #333;
  text-align: center;
  min-height: 160px;
}

body > .el-container {
  margin-bottom: 40px;
}

.videoboxup {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(34, 34, 34, 0.381);
}
</style>
