<template>
  <!-- 核心能力 -->
  <div class="CoreMainBox">
    <div class="bannerOneBox">
      <div class="bannerTextBox">
        <div class="textstyleO">核心能力</div>
        <div class="textstyleT">CORE COMPETENCE</div>
        <div class="textstyleTh" style=" font-weight: 400">
          科技赋能 <br />数据驱动<br />
          服务创新
        </div>
      </div>
    </div>
    <!-- 实时智慧监护技术 -->
    <div class="MonitoringTechnologybox">
      <h1 class="healthRisksContenth1" id="2-1">实时智慧监护技术</h1>
      <h3 class="CoreCompetenceh3">技术原理图</h3>
      <div class="CoreCompetenceMap"></div>
      <div class="CoreTextbox">
        <div class="CoreText">
          通过将智能设备采集到的健康数据应用物联网技术实时传输至云端，由智慧健康监护系统（SHMS）的核心AI算法量化健康质量与健康问题，通过建立监测数据与老年健康的关联模型预警可能存在的健康风险，并实现健康数据的远程实时持续管理、分析、处理、共享。
        </div>
        <div class="CoreTextList">
          <div
            class="CoreTextItem"
            v-for="item in CoreTextItemData"
            :key="item.id"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <!-- 远程智慧监护中心 -->
    <div class="custodyBgbox">
      <h1 class="healthRisksContenth1" id="2-2" style="color: #fff">
        远程智慧监护中心
      </h1>
      <div class="custodycontentbox">
        <div
          class="custodycontentItembox"
          v-for="item in custodycontentItemdata"
          :key="item.id"
        >
          <img
            :src="require(`../../assets/CoreCompetence/${item.img}.png`)"
            alt=""
          /><br />
          <h3 class="custodycontentItemh3">
            {{ item.name }}
          </h3>
          <p class="custodycontentItemintroduce">{{ item.introduce }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CoreCompetence",
  props: {},
  data() {
    return {
      CoreTextItemData: [
        { name: "集成传感技术", id: 1 },
        { name: "智慧健康监护系统", id: 2 },
        { name: "实时风险评估和响应技术", id: 3 },
        { name: "健康老龄化风险关键因子分析技术", id: 4 },
        { name: "老年健康风险核心算法", id: 5 },
      ],
      custodycontentItemdata: [
        {
          name: "先进技术",
          img: "advancedTechnique",
          introduce:
            "我们的监护中心采用先进的智能化健康监护系统（SHMS）技术，确保服务的高可用和高冗余。",
          id: 1,
        },
        {
          name: "优质服务",
          img: "goodService",
          introduce:
            "我们在老年健康监护的每个服务接触点提供最佳体验，确保长者获得最好的监护服务效果。",
          id: 2,
        },
        {
          name: "专业培训",
          img: "ProfessionalTraining",
          introduce:
            "我们的监护团队经过专业培训，具备专业老年健康知识和及时反应能力。",
          id: 3,
        },
      ],
    };
  },
  mounted() {
    document.querySelector(".bannerOneBox").style.height =
      (document.body.clientWidth / 19.2) * 6 + "px";
    document.querySelector(".custodyBgbox").style.height =
      (document.body.clientWidth / 19.2) * 9 + "px";
    window.addEventListener("resize", () => {
      document.querySelector(".bannerOneBox").style.height =
        (document.body.clientWidth / 19.2) * 6 + "px";
      document.querySelector(".custodyBgbox").style.height =
        (document.body.clientWidth / 19.2) * 9 + "px";
    });
  },
};
</script>

<style scoped>
.CoreMainBox {
  overflow: hidden;
  width: 100%;
}
.bannerOneBox {
  width: 100%;
  position: relative;
  min-width: 1200px;
  background: url(../../assets/CoreCompetence/bannerCoreCompetence_3.png)
    no-repeat center center;
  background-size: 100% 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bannerTextBox {
  width: 1200px;
  color: #fff;
  text-align: left;
}
.textstyleO {
  font-size: calc(60 / 19.2 * 1vw);
  height: 1.05rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 1.05rem;
}
.textstyleT {
  height: .4125rem;
  font-size: calc(24 / 19.2 * 1vw);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: .4125rem;
  /* letter-spacing: 10px; */
  margin-bottom: .5rem
}
.textstyleTh {
  font-size: calc(34 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: .6rem;
}

.healthRisksContenth1 {
  display: inline-block;
  margin: 0.75rem auto 0px;
  position: relative;
  font-size: calc(50 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 70px;
}
.healthRisksContenth1::after {
  content: "";
  position: absolute;
  top: 92px;
  left: 50%;
  transform: translate(-50%);
  width: 120px;
  height: 8px;
  background: #0f6bb0;
}
.CoreCompetenceh3 {
  height: 56px;
  font-size: calc(40 / 19.2 * 1vw);
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0f6bb0;
  line-height: 56px;
}
.MonitoringTechnologybox {
  width: 1200px;
  margin: 0 auto;
}

.CoreCompetenceMap {
  height: 584px;
  width: 1055px;
  background: url(../../assets/CoreCompetence/CoreCompetenceMapImg.png)
    no-repeat center center;
  background-size: 100% 100%;

  margin: 0 auto;
}
.CoreTextbox {
  width: 1200px;
  height: 376px;
  background: #ffffff;
  box-shadow: 0px 4px 14px 0px rgba(202, 217, 229, 0.7);
  border-radius: 40px;
  margin: 60px auto 80px;
  padding: 40px 60px;
  box-sizing: border-box;
}
.CoreText {
  font-size: calc(24 / 19.2 * 1vw);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #355e7d;
  line-height: 40px;
  margin-bottom: 30px;
  text-align: justify;
}
.CoreTextList {
  height: 146px;

  background: #eafcff;
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 80px;
  box-sizing: border-box;
  justify-content: space-between;
}
.CoreTextItem:nth-child(2n + 1) {
  width: 300px;
}
.CoreTextItem {
  height: 33px;
  font-size: calc(24 / 19.2 * 1vw);
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 33px;
  position: relative;
  text-align: left;
  padding-left: 30px;
  box-sizing: border-box;
}
.CoreTextItem::before {
  content: "";
  width: 10px;
  height: 10px;
  background: #6fc7d5;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
}

.custodyBgbox {
  width: 100%;
  height: 902px;
  background: url(../../assets/CoreCompetence/custodyBg.png) no-repeat center
    center;
  background-size: 100% 100%;
}

.custodycontentbox {
  width: 1200px;
  height: 64%;
  margin: 4% auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custodycontentItembox {
  height: 100%;
  width: calc(33.33% - 10px);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  /* filter: blur(10px); */
  padding: 3%;
  box-sizing: border-box;
}
.custodycontentItembox img {
  width: calc(143 / 19.2 * 1vw);
}
.custodycontentItemh3 {
  display: inline-block;
  margin: 10% auto 0px;
  position: relative;
  font-size: calc(40 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 56px;
}
.custodycontentItemh3::before {
  content: "";
  position: absolute;
  top: 62px;
  left: 50%;
  transform: translate(-50%);
  width: 60px;
  height: 6px;
  background: #6fc7d5;
}
.custodycontentItemintroduce {
  font-size: calc(24 / 19.2 * 1vw);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 200%;
  text-align: justify;
  word-break: normal;
}
</style>
