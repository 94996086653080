<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <Index />
  </div>
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";
import Index from "./views/Index.vue";

export default {
  name: "App",
  components: {
    // HelloWorld,
    Index,
  },
};
</script>

<style>
html,
body,
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  margin: 0;
  padding: 0;
  /* background-color: aqua; */
}
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 80px !important;
}
</style>
