<template>
  <div>
    <div v-if="flag == 1"><HomeP @hideTableFunP="hideTableFuncp" /></div>
    <div v-if="flag == 2"><CoreCompetence /></div>
    <div v-if="flag == 3"><ServiceCapability /></div>
    <div v-if="flag == 4"><Solution /></div>
    <div v-if="flag == 5"><AboutUs /></div>
    <div v-if="flag == 6"><AgreementUser /></div>
    <div v-if="flag == 7"><PrivacyClause /></div>
  </div>
</template>

<script>
import HomeP from "../components/mainComp/HomeP.vue";
import CoreCompetence from "../components/mainComp/CoreCompetence.vue";
import ServiceCapability from "../components/mainComp/ServiceCapability.vue";
import Solution from "../components/mainComp/Solution.vue";
import AboutUs from "../components/mainComp/AboutUs.vue";
import AgreementUser from "../components/mainComp/agreementUser.vue";
import PrivacyClause from "../components/mainComp/PrivacyClause.vue";
export default {
  name: "Index",
  components: {
    HomeP,
    CoreCompetence,
    ServiceCapability,
    Solution,
    AboutUs,
    AgreementUser,
    PrivacyClause,
  },
  props: ["clockButtonFlags"],
  data() {
    return {
      flag: 1,
    };
  },
  methods: {
    hideTableFuncp(xx) {
      console.log("11111", xx);
      this.$emit("hideTableFun", xx);
    },
    delayFun(strId, that) {
      console.log("延迟", strId, that);
      if (document.getElementById(strId)) {
        document.getElementById(strId).scrollIntoView();
        console.log("结束");
        return;
      } else {
        setTimeout(function () {
          that.delayFun(strId);
        }, 100);
      }
    },
  },
  watch: {
    clockButtonFlags(newVal, oldVal) {
      console.log("main监听", newVal.charAt(0));
      this.flag = newVal.charAt(0);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.delayFun(newVal, this);

      // document.getElementById("here").scrollIntoView();
    },
  },
};
</script>

<style scoped>
</style>