<template>
  <!-- 服务能力 -->
  <div class="ServiceCapabilityBox">
    <div class="bannerOneBox">
      <div class="bannerTextBox">
        <div class="textstyleO">服务能力</div>
        <div class="textstyleT">SERVICE CAPABILITY</div>
        <div class="textstyleTh" style="font-weight: 400">
          健康老龄化与数字化服务的融合
        </div>
      </div>
    </div>
    <!-- 老年健康监护服务 -->
    <div class="guardianshipServicesBox">
      <h1 class="healthRisksContenth1" id="3-1">老年健康监护服务</h1>
      <div class="serviceContentContentBox">
        <div class="serviceContentTitle">服务内容</div>
        <div class="serviceContentBox">
          <div
            class="serviceContentItem serviceContentItem_1"
            v-for="item in serviceContentlistOne"
            :key="item.id"
            :style="[{ background: item.background }, { height: item.height }]"
          >
            <img
              class="serviceContentItemImg serviceContentItemImg_1"
              :src="require(`../../assets/ServiceCapability/${item.img}.png`)"
              alt=""
              width="120"
            />
            <div class="serviceContentItemText serviceContentItemText_1">
              {{ item.introduce }}
            </div>
          </div>
        </div>
        <div class="serviceContentTitle">服务流程</div>
        <div class="serviceContentImg">
          <img
            :src="
              require(`../../assets/ServiceCapability/emergencyResponseImg_1.png`)
            "
            alt=""
            width="100%"
          />
        </div>
        <div class="monitoringEquipmentTitle">监测设备</div>

        <div class="monitoringEquipmentImgBox">
          <div class="leftArrow">
            <img
              src="../../assets/ServiceCapability/left.png"
              width="40"
              height="130"
              @click="arrowClick('left')"
            />
          </div>
          <el-carousel
            indicator-position="none"
            height="5rem"
            :autoplay="false"
            arrow="never"
            ref="cardShow"
          >
            <el-carousel-item v-for="item in carouseldata.one" :key="item.id">
              <div class="monitoringEquipmentImgItem">
                <div
                  class="ImgItem"
                  v-for="imgList in item.img"
                  :key="imgList.id"
                >
                  <div
                    class="ImgItemText"
                    @click="imgClickFunOne(imgList.imgItem)"
                  >
                    <span v-if="flagTextOne !== imgList.imgItem">{{
                      imgList.title
                    }}</span>
                  </div>
                  <img
                    :src="
                      require(`../../assets/ServiceCapability/${imgList.imgItem}.png`)
                    "
                    alt=""
                  />
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="rightArrow">
            <img
              src="../../assets/ServiceCapability/right.png"
              width="40"
              height="130"
              @click="arrowClick('right')"
            />
          </div>
        </div>
        <!-- 65-->
        <div v-for="item in dataIntroduceBOX" :key="item.id">
          <div class="titleList">{{ item.title }}</div>
          <div v-for="j in item.textlist" :key="j.id">
            <div class="dataIntroducetextlist">{{ j.name }}</div>
            <div
              class="listIntroducelist"
              v-for="list in j.introducelist"
              :key="list.id"
            >
              {{ list.introduce }}
            </div>
          </div>
        </div>

        <!-- 65 -->
      </div>
    </div>
    <!-- 应急响应服务 -->
    <div class="responseServiceBox">
      <h1 class="healthRisksContenth1" id="3-2">应急响应服务</h1>
      <div class="serviceContentContentBox">
        <div class="serviceContentTitle">服务内容</div>
        <div class="serviceContentBox">
          <div
            class="serviceContentItem"
            v-for="item in serviceContentlist"
            :key="item.id"
            :style="[{ background: item.background }, { height: item.height }]"
          >
            <img
              class="serviceContentItemImg"
              :src="require(`../../assets/ServiceCapability/${item.img}.png`)"
              alt=""
              width="120"
            />
            <div class="serviceContentItemText">{{ item.introduce }}</div>
          </div>
        </div>
        <div class="serviceContentTitle">服务流程</div>
        <div class="serviceContentImg">
          <img
            :src="
              require(`../../assets/ServiceCapability/${emergencyResponseImg}.png`)
            "
            alt=""
            width="100%"
          />
        </div>
        <div class="monitoringEquipmentTitle">监测设备</div>
        <!--  -->
        <div class="monitoringEquipmentImgBox">
          <div class="leftArrow">
            <img
              src="../../assets/ServiceCapability/left.png"
              width="40"
              height="130"
              @click="arrowClick('left_1')"
            />
          </div>
          <el-carousel
            indicator-position="none"
            height="5rem"
            :autoplay="false"
            arrow="never"
            ref="cardShow_1"
          >
            <el-carousel-item v-for="item in carouseldata.two" :key="item.id">
              <div class="monitoringEquipmentImgItem">
                <div
                  class="ImgItem"
                  v-for="imgList in item.img"
                  :key="imgList.id"
                >
                  <div
                    class="ImgItemText"
                    @click="imgClickFunTwo(imgList.imgItem)"
                  >
                    <span v-if="flagTextTwo !== imgList.imgItem">{{
                      imgList.title
                    }}</span>
                  </div>
                  <img
                    :src="
                      require(`../../assets/ServiceCapability/${imgList.imgItem}.png`)
                    "
                    alt=""
                  />
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="rightArrow">
            <img
              src="../../assets/ServiceCapability/right.png"
              width="40"
              height="130"
              @click="arrowClick('right_1')"
            />
          </div>
        </div>
        <div v-for="item in dataIntroduceBOX_1" :key="item.id">
          <div class="titleList">{{ item.title }}</div>
          <div class="textlistBox">
            <div v-for="j in item.textlist" :key="j.id">
              <div class="dataIntroducetextlist_1">{{ j.name }}</div>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </div>
    <!-- 智慧健康养老 -->
    <div class="halthyPensionBox">
      <h1 class="healthRisksContenth1" id="3-3">智慧健康养老</h1>
      <div class="halthyPensionItemBox">
        <div
          class="halthyPensionItem"
          v-for="item in halthyPensionData"
          :key="item.id"
        >
          <img
            :src="require(`../../assets/ServiceCapability/${item.img}.png`)"
            alt=""
            width="590"
          />
          <span class="halthyPensionspan">{{ item.name }}</span>
          <span class="halthyPensionText">{{ item.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "ServiceCapability",
  props: {},
  data() {
    return {
      flagTextOne: "img_1L",
      flagTextTwo: "img_4L",
      arr: [
        {
          id: 1,
          title: "",
          textlist: [],
        },
      ],
      dataIntroduceBOX: [
        {
          id: 1,
          title: "健康监测仪",
          textlist: [
            {
              id: 11,
              name: "基于心冲击图（BCG）技术",
            },
            {
              id: 12,
              name: "可监测数据",
              introducelist: [
                {
                  id: 121,
                  introduce: "心率、房颤、呼吸、夜尿、睡眠分期、睡眠时间",
                },
              ],
            },
            {
              id: 13,
              name: "报告显示",
              introducelist: [
                {
                  id: 131,
                  introduce:
                    "睡眠质量评估、睡眠分期记录、作息时间记录、体动翻身情况、静息心率图谱、身体机能恢复评估、疲劳度评估、呼吸暂停综合征筛查、房颤筛查、个人健康数据建模",
                },
              ],
            },
            {
              id: 14,
              name: "告警信息",
              introducelist: [
                {
                  id: 141,
                  introduce: "作息异常、体征异常、呼吸暂停综合症、房颤…",
                },
              ],
            },
          ],
        },
      ],
      dataIntroduce: [
        {
          id: 1,
          title: "健康监测仪",
          textlist: [
            {
              id: 11,
              name: "基于心冲击图（BCG）技术",
            },
            {
              id: 12,
              name: "可监测数据",
              introducelist: [
                {
                  id: 121,
                  introduce: "心率、房颤、呼吸、夜尿、睡眠分期、睡眠时间",
                },
              ],
            },
            {
              id: 13,
              name: "报告显示",
              introducelist: [
                {
                  id: 131,
                  introduce:
                    "睡眠质量评估、睡眠分期记录、作息时间记录、体动翻身情况、静息心率图谱、身体机能恢复评估、疲劳度评估、呼吸暂停综合征筛查、房颤筛查、个人健康数据建模",
                },
              ],
            },
            {
              id: 14,
              name: "告警信息",
              introducelist: [
                {
                  id: 141,
                  introduce: "作息异常、体征异常、呼吸暂停综合症、房颤…",
                },
              ],
            },
          ],
        },
      ],
      dataIntroduce_0: [
        {
          id: 0,
          title: "平衡体脂秤",
          textlist: [
            {
              id: 11,
              name: "监测指标",
              introducelist: [
                {
                  id: 111,
                  introduce:
                    "体重、重心平衡、BMI、脂肪率、肌肉率、去脂体重、皮下脂肪、内脏脂肪、体水分、骨骼肌率、肌肉量、骨量、蛋白质、基础代谢、脂肪量、含水量、肥胖等级、体型",
                },
              ],
            },
          ],
        },
      ],
      dataIntroduce_3: [
        {
          id: 1,
          title: "心电记录仪",
          textlist: [
            {
              id: 11,
              name: "监测心电活动指标",
            },
            {
              id: 12,
              name: "智能分析52种心律异常",
              introducelist: [
                {
                  id: 121,
                  introduce: "",
                },
              ],
            },
            {
              id: 13,
              name: "心电专家人工判读咨询（可选）",
              introducelist: [
                {
                  id: 131,
                  introduce: "",
                },
              ],
            },
          ],
        },
      ],
      dataIntroduceBOX_1: [
        {
          id: 0,
          title: "4D生物雷达",
          textlist: [
            {
              id: 11,
              name: "高分辨率毫米波成像技术",
            },
            {
              id: 12,
              name: "140度视野角",
            },
            {
              id: 13,
              name: "最大支持20平米覆盖范围",
            },
            {
              id: 14,
              name: "抗干扰性强，高精准，高实时性",
            },
            {
              id: 15,
              name: "被动监测技术、自动报警、无感体验",
            },
            {
              id: 16,
              name: "跌倒警报、行为识别…",
            },
          ],
        },
      ],
      dataIntroduce_1: [
        {
          id: 0,
          title: "4D生物雷达",
          textlist: [
            {
              id: 11,
              name: "高分辨率毫米波成像技术",
            },
            {
              id: 12,
              name: "140度视野角",
            },
            {
              id: 13,
              name: "最大支持20平米覆盖范围",
            },
            {
              id: 14,
              name: "抗干扰性强，高精准，高实时性",
            },
            {
              id: 15,
              name: "被动监测技术、自动报警、无感体验",
            },
            {
              id: 16,
              name: "跌倒警报、行为识别…",
            },
          ],
        },
      ],
      dataIntroduce_10: [
        {
          id: 0,
          title: "智能居家安全套装",
          textlist: [
            {
              id: 11,
              name: "AI看护摄像头",
            },
            {
              id: 12,
              name: "智能烟雾报警器",
            },
            {
              id: 13,
              name: "智能水浸探测器",
            },
            {
              id: 14,
              name: "智能紧急按钮",
            },
            {
              id: 15,
              name: "智能网关",
            },
            {
              id: 16,
              name: "智能红外探测器",
            },
            {
              id: 17,
              name: "智能可燃气体报警器",
            },
            {
              id: 18,
              name: "智能门磁探测器",
            },
            {
              id: 19,
              name: "智能手环/手表",
            }
          ],
        },
      ],
      halthyPensionData: [
        {
          name: "智慧健康养老平台",
          text: "涵盖机构养老、居家养老、社区养老、医养结合和政府监管的信息化管理需求",
          img: "pensionPlatformImg",
          id: 0,
        },
        {
          name: "智慧健康养老设备",
          text: "健康监测设备、安全监测设备、照护管理设备、长者饭堂设备、机构出入管理设备",
          img: "pensionEquipmentImg",
          id: 2,
        },
      ],
      carouseldata: {
        one: [
          {
            id: 0,
            img: [
              { id: 11, imgItem: "img_1L", title: "健康监测仪" },
              { id: 12, imgItem: "img_2", title: "平衡体脂秤" },
            ],
          },
          {
            id: 2,
            img: [
              { id: 21, imgItem: "img_3", title: "心电记录仪" },
              { id: 22, imgItem: "img_1L", title: "健康监测仪" },
            ],
          },
          {
            id: 3,
            img: [
              { id: 31, imgItem: "img_2", title: "平衡体脂秤" },
              { id: 32, imgItem: "img_3", title: "健康监测仪" },
            ],
          },
          {
            id: 4,
            img: [
              { id: 41, imgItem: "img_1L", title: "健康监测仪" },
              { id: 42, imgItem: "img_2", title: "平衡体脂称" },
            ],
          },
        ],

        two: [
          {
            id: 0,
            img: [
              { id: 11, imgItem: "img_4L", title: "4D生物雷达" },
              { id: 12, imgItem: "img_5", title: "智能居家安全套装" },
            ],
          },
          {
            id: 2,
            img: [
              { id: 21, imgItem: "img_4L", title: "4D生物雷达" },
              { id: 22, imgItem: "img_5", title: "智能居家安全套装" },
            ],
          },
          {
            id: 3,
            img: [
              { id: 31, imgItem: "img_4L", title: "4D生物雷达" },
              { id: 32, imgItem: "img_5", title: "智能居家安全套装" },
            ],
          },
        ],
      },

      serviceContentlistOne: [
        {
          id: 0,
          background: "#F3FAFF",
          height: "3.375rem",
          img: "serviceContentImg",
          introduce:
            "实时监测窦性心动过缓、呼吸暂停、心律不齐、房颤、睡眠障碍、夜尿症、平衡障碍、营养不良、肥胖、压疮等健康风险数据。",
        },
        {
          id: 2,
          img: "serviceContentImg_2",
          background: "#F3FAFF",
          height: "3.375rem",
          introduce: "智能分析用户的健康趋势及异常变化。",
        },
        {
          id: 3,
          background: "#F3FAFF",
          height: "3.375rem",
          img: "serviceContentImg_3",
          introduce:
            "通过手机客户端每天实时同步长者健康数据，推送健康风险预警信息，反馈监测报告。",
        },
        {
          id: 4,
          img: "serviceContentImg_4",
          background: "#F3FAFF",
          height: "3.375rem",
          introduce: "由监护中心健康顾问提供全天监护。",
        },
      ],
      serviceContentlist: [
        {
          id: 1,
          background: "#FFF",
          height: "200px",
          img: "sosImg",
          introduce:
            "在紧急情况下，按下智能监护设备的SOS报警按键即可寻求帮助，报警信号将立即传送到监护中心启动应急响应服务。",
        },
        {
          id: 2,
          img: "radarImg",
          background: "#FFF",
          height: "200px",
          introduce:
            "当发生跌倒等意外事件时，4D生物雷达等智能监护设备将第一时间捕捉到异常，并将报警信号立即传送到监护中心启动应急响应服务。",
        },
      ],
      emergencyResponseImg: "emergencyResponseImg",
    };
  },
  mounted() {
    document.querySelector(".bannerOneBox").style.height =
      (document.body.clientWidth / 19.2) * 6 + "px";

    window.addEventListener("resize", () => {
      document.querySelector(".bannerOneBox").style.height =
        (document.body.clientWidth / 19.2) * 6 + "px";
    });
  },

  methods: {
    arrowClick(val) {
      if (val === "right") {
        this.$refs.cardShow.next();
      } else if (val === "left") {
        this.$refs.cardShow.prev();
      }
      if (val === "right_1") {
        this.$refs.cardShow_1.next();
      } else if (val === "left_1") {
        this.$refs.cardShow_1.prev();
      }
    },
    imgClickFunOne(v) {
      this.carouseldata.one.map((item) => {
        item.img.map((i) => {
          if (i.imgItem.charAt(i.imgItem.length - 1) == "L") {
            i.imgItem = i.imgItem.substr(0, i.imgItem.length - 1);
          }
          if (v !== "img_1" && v !== "img_3") {
            this.dataIntroduceBOX = [];
          }

          if (v == "img_1") {
            this.dataIntroduceBOX = JSON.parse(
              JSON.stringify(this.dataIntroduce)
            );
          }
          if (v == "img_3") {
            this.dataIntroduceBOX = JSON.parse(
              JSON.stringify(this.dataIntroduce_3)
            );
          }

          if (i.imgItem == v) {
            if (v.charAt(i.imgItem.length - 1) !== "L") {
              i.imgItem = i.imgItem + "L";
              this.flagTextOne = v + "L";
              if (v == "img_3") {
                console.log(888);
                this.dataIntroduceBOX = JSON.parse(
                  JSON.stringify(this.dataIntroduce_3)
                );
                return;
              }
              if (v == "img_2") {
                this.dataIntroduceBOX = JSON.parse(
                  JSON.stringify(this.dataIntroduce_0)
                );
                return;
              }
              if (v == "img_1") {
                this.dataIntroduceBOX = JSON.parse(
                  JSON.stringify(this.dataIntroduce)
                );
                return;
              }
            }
          }
        });
      });
    },
    imgClickFunTwo(v) {
      this.carouseldata.two.map((item) => {
        item.img.map((i) => {
          if (i.imgItem.charAt(i.imgItem.length - 1) == "L") {
            i.imgItem = i.imgItem.substr(0, i.imgItem.length - 1);
          }
          if (v !== "img_4") {
            this.dataIntroduceBOX_1 = [];
          }
          if (v == "img_4") {
            this.dataIntroduceBOX_1 = JSON.parse(
              JSON.stringify(this.dataIntroduce_1)
            );
          }

          if (i.imgItem == v) {
            if (v.charAt(i.imgItem.length - 1) !== "L") {
              i.imgItem = i.imgItem + "L";
              console.log("77878", v);
              this.flagTextTwo = v + "L";
              if (v == "img_4") {
                this.dataIntroduceBOX_1 = JSON.parse(
                  JSON.stringify(this.dataIntroduce_1)
                );
                return;
              }
              if (v == "img_5") {
                this.dataIntroduceBOX_1 = JSON.parse(
                  JSON.stringify(this.dataIntroduce_10)
                );
                return;
              }
            }
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.ServiceCapabilityBox {
  overflow: hidden;
  width: 100%;
}
.bannerOneBox {
  width: 100%;
  position: relative;
  min-width: 1200px;
  background: url(../../assets/ServiceCapability/bannerServiceCapability_3.png)
    no-repeat center center;
  background-size: 100% 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bannerTextBox {
  width: 1200px;
  color: #fff;
  text-align: left;
}
.textstyleO {
  font-size: calc(60 / 19.2 * 1vw);
  height: 1.05rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 1.05rem;
}
.textstyleT {
  height: 0.4125rem;
  font-size: calc(24 / 19.2 * 1vw);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.4125rem;
  /* letter-spacing: 10px; */
  margin-bottom: 0.5rem;
}
.textstyleTh {
  font-size: calc(34 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.6rem;
}

.healthRisksContenth1 {
  display: inline-block;
  margin: 0.75rem auto 0px;
  position: relative;
  font-size: calc(50 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 70px;
}
.healthRisksContenth1::after {
  content: "";
  position: absolute;
  top: 92px;
  left: 50%;
  transform: translate(-50%);
  width: 120px;
  height: 8px;
  background: #0f6bb0;
}
/* 老年健康监护服务 */
.guardianshipServicesBox {
  width: 1200px;
  /* height: 2586px; */
  margin: 0 auto;
}
.serviceContentItem_1 {
  padding: 0.375rem !important;
}
.serviceContentItemImg_1 {
  margin-right: 0.5rem !important;
}
.serviceContentItemText_1 {
  font-size: 0.325rem !important;
  line-height: 0.525rem !important;

}

/* 监测设备_0 */
.monitoringEquipmentImgBox {
  width: 15rem;
  position: relative;
  margin: 30px auto;
}
/* .el-carousel__arrow--right {
  background: #000 !important;
} */
.leftArrow {
  position: absolute;
  top: 50%;
  left: -70px;
  transform: translate(0, -50%);
}
.rightArrow {
  position: absolute;
  top: 50%;
  right: -70px;
  transform: translate(0, -50%);
}
/* .el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
} */

/* .el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #0076fd;
} */

.monitoringEquipmentImgItem {
  /* width: 1200px; */
  height: 5rem;
  /* background: #000; */
  display: flex;
  justify-content: center;
  justify-items: center;
}
.ImgItem {
  height: 5rem;
  width: 7.375rem;
  border-radius: 15px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  overflow: hidden;
  margin: 0 0.1875rem;
  font-size: 0.375rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 5rem;
  position: relative;
}
.ImgItemText {
  height: 5rem;
  width: 7.375rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.ImgItem img {
  width: 110.7%;
  background-color: rgb(218 219 222);
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 监测设备_1 */

.titleList {
  height: 56px;
  font-size: 0.5rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 56px;
  text-align: left;
  position: relative;
  margin-bottom: 46px;
}
.titleList::before {
  content: "";
  width: 60px;
  height: 6px;
  background: #0f6bb0;
  position: absolute;
  top: 70px;
}

.dataIntroducetextlist {
  height: 0.525rem;
  font-size: 0.375rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.525rem;
  position: relative;
  padding-left: 20px;
  text-align: left;
  margin: 0.25rem 0px;
}
.dataIntroducetextlist::before {
  content: "";
  width: 8px;
  height: 8px;
  background: #0f6bb0;
  position: absolute;
  top: 50%;
  left: 5px;
  border-radius: 50%;
  transform: translate(0, -50%);
}
.textlistBox {
  height: 3.75rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* background: #000; */
}

.dataIntroducetextlist_1 {
  height: 0.525rem;
  font-size: 0.375rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.525rem;
  position: relative;
  padding-left: 20px;
  text-align: left;
  margin: 0.25rem 0px;
}
.dataIntroducetextlist_1::before {
  content: "";
  width: 8px;
  height: 8px;
  background: #0f6bb0;
  position: absolute;
  top: 50%;
  left: 5px;
  border-radius: 50%;
  transform: translate(0, -50%);
}
.listIntroducelist {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 40px;
  text-align: left;
  padding-left: 20px;
}

/* <!-- 应急响应服务 --> */
.responseServiceBox {
  width: 100%;
  /* height: 1844px; */
  background: #f3faff;
}

.serviceContentContentBox {
  width: 1200px;
  margin: 0.7rem auto 0.5rem;
}

.serviceContentTitle {
  height: 42px;
  font-size: 0.375rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 42px;
  text-align: left;
  padding-left: 10px;
  box-sizing: border-box;
  position: relative;
}
.serviceContentTitle::before {
  content: "";
  width: 4px;
  height: 24px;
  background: #0f6bb0;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translate(0, -50%);
}

.serviceContentBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px 35px;
}
.serviceContentItem {
  width: 590px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  text-align: left;
  padding: 40px 20px;
  box-sizing: border-box;
  margin: 10px 0;
}
.serviceContentItemImg {
  margin-right: 20px;
}
.serviceContentItemText {
  font-size: 0.275rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 36px;
  text-align: justify;
}
.serviceContentImg {
  margin: 20px auto 60px;
}

.monitoringEquipmentTitle {
  height: 56px;
  font-size: 0.5rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0f6bb0;
  line-height: 56px;
  text-align: left;
}
/* 智慧健康养老 */
.halthyPensionBox {
  width: 1200px;
  height: 746px;
  margin: 0 auto;
}
.halthyPensionItemBox {
  width: 100%;
  height: 354px;
  margin: 60px auto 80px;
  display: flex;
  justify-content: space-between;
}

.halthyPensionItem {
  width: 590px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  margin-top: 66px;
}
.halthyPensionText {
  height: 44px;
  width: 400px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
.halthyPensionspan {
  width: 240px;
  height: 42px;
  font-size: 0.375rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0f6bb0;
  line-height: 42px;
  margin: 20px;
}
</style>
