<template>
  <!-- 关于我们 -->
  <div class="AboutUsBox">
    <div class="bannerOneBox">
      <div class="bannerTextBox">
        <div class="textstyleO">关于我们</div>
        <div class="textstyleT">ABOUT US</div>
        <div class="textstyleTh">
          银康数科是老年健康照护“技术+产品+服务+数据”集成商和运营商
        </div>
      </div>
    </div>
    <!--  -->
    <div class="AboutUsButtonBox">
      <div
        class="AboutUsButtonItem"
        v-for="item in AboutUsButtonData"
        :key="item.id"
      >
        <img
          :src="require(`../../assets/AboutUs/${item.img}.png`)"
          alt=""
          width="140"
        />
        <span class="AboutUsButtonItemText">{{ item.text }}</span>
        <span class="AboutUsButtonItemspan">{{ item.name }}</span>
      </div>
    </div>
    <!-- 公司简介 -->
    <div class="CompanyProfile">
      <div>
        <div class="healthRisksContenth1">公司简介</div>
        <div class="CompanyProfileTextBox">
          <div class="CompanyProfileText">
            银康数科是老年健康照护“技术+产品+服务+数据”集成商和运营商。通过数字化健康照护平台、区域照护服务网络相结合的健康老龄化服务体系，为养老、医养结合、金融、保险、老干保健等产业和事业赋能数字化老年健康照护服务能力，提供远程健康监护、智慧健康养老、应急响应等端到端、线上线下无缝链接的跨业务、多场景老年健康照护服务体验。
          </div>
        </div>
      </div>
    </div>

    <!-- 相关下载 -->
    <div class="healthRisksContenth1">相关下载</div>
    <div class="relatedDownloadBox">
      <div
        class="relatedDownloadItem"
        v-for="item in relatedDownloadItemData"
        :key="item.id"
      >
        <span class="relatedDownloadItemspan">{{ item.name }}</span>
        <img
          :src="require(`../../assets/AboutUs/${item.img}.png`)"
          alt=""
          width="160"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  props: {},
  data() {
    return {
      relatedDownloadItemData: [
        { name: "长者公社微信公众号", img: "officialAccountImg", id: 1 },
        { name: "长者公社小程序", img: "appletImg", id: 2 },
      ],
      AboutUsButtonData: [
        {
          name: "助力健康老龄化",
          text: "我们的使命",
          img: "ourMissionImg",
          id: 1,
        },
        {
          name: "数字化老年健康照护的领航者",
          text: "我们的愿景",
          img: "ourVisionImg",
          id: 2,
        },
      ],
    };
  },
  mounted() {
    document.querySelector(".bannerOneBox").style.height =
      (document.body.clientWidth / 19.2) * 4.4 + "px";

    window.addEventListener("resize", () => {
      document.querySelector(".bannerOneBox").style.height =
        (document.body.clientWidth / 19.2) * 4.4 + "px";
    });
  },
};
</script>

<style scoped>
.AboutUsBox {
  overflow: hidden;
  width: 100%;
}
.bannerOneBox {
  width: 100%;
  position: relative;
  min-width: 1200px;
  background: url(../../assets/AboutUs/bannerAboutUs.png) no-repeat center
    center;
  background-size: 100% 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bannerTextBox {
  width: 1200px;
  color: #fff;
}
.textstyleO {
  /* margin-top: .25rem; */
  font-size: calc(60 / 19.2 * 1vw);
  height: 1.05rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 1.05rem;
}
.textstyleT {
  font-size: calc(20 / 19.2 * 1vw);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: .35rem;
  letter-spacing: 10px;
  margin-bottom: .35rem;
  letter-spacing: calc(19 / 19.2 * 1vw);
}
.textstyleTh {
  font-size: calc(24 / 19.2 * 1vw);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: .45rem;
  
}

.healthRisksContenth1 {
  display: inline-block;
  margin: 0.75rem auto 0px;
  position: relative;
  font-size: calc(50 / 19.2 * 1vw);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 70px;
}
.healthRisksContenth1::after {
  content: "";
  position: absolute;
  top: 92px;
  left: 50%;
  transform: translate(-50%);
  width: 120px;
  height: 8px;
  background: #0f6bb0;
}

.CompanyProfile {
  height: 6.725rem;
  width: 100%;
  background: #e7f0f7;
}

.CompanyProfileTextBox {
  width: 1200px;
  height: 3.1rem;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 12px;
  text-align: justify;
  padding: .5rem .625rem;
  box-sizing: border-box;
  margin: .75rem auto 0px;
  overflow: hidden;
}
.CompanyProfileText {
  font-size: .3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 400;
  line-height: .525rem;
  margin: 0 auto;
}
.CompanyProfileText::first-letter {
  font-size: .625rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0f6bb0;
  line-height: .525rem;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 12px;
}

.relatedDownloadBox {
  width: 1200px;
  height: 2.75rem;
  margin: .825rem auto 1rem;
  display: flex;
  justify-content: center;
}
.relatedDownloadItem {
  width: 7.375rem;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 14px 0px rgba(202, 217, 229, 0.7);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0px .125rem;
}
.relatedDownloadItemspan {
  font-size: .375rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: .525rem;
}
.AboutUsButtonBox {
  width: 1200px;
  height: 4.425rem;
  margin: .75rem auto 1rem;
  display: flex;
  justify-content: center;
}
.AboutUsButtonItem {
  width: 7.375rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #4493cd;
  border-radius: 12px;
  margin: 0 10px;
}
.AboutUsButtonItemText {
  font-size: .25rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: .35rem;
  margin: .25rem;
}
.AboutUsButtonItemspan {
  height: .7rem;
  font-size: .5rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: .7rem;
}
</style>

